import React from "react";
import { ContentManagementWrapped } from "../style";
import SideBarTab from "../../components/tabs/SideBarTab";
import HomeContentPage from "./HomeContentPage";
import PrivacyPage from "./PrivacyPage";
import TermsPage from "./TermsPage";
import AboutPage from "./AboutPage";
import CookiePolicyPage from "./CookiePolicyPage";
import WebSiteFooter from "./WebSiteFooter";
import GalleryPage from "./GalleryPage";
import CommercialSolarPage from "./CommercialSolarPage";
import ResidentialSolarPage from "./ResidentialSolarPage";
import SolarPanelsPage from "./SolarPanelsPage";
import BatteryStoragePage from "./BatteryStoragePage";
import ChargingPage from "./ChargingPage";
import LightningPage from "./LightningPage";
import ShiftPage from "./ShiftPage";
import TeslaPowerWallPage from "./TeslaPowerWallPage";
import GetQuotePage from "./GetQuotePage";
import PlanPage from "./PlanPage";

const ContentManagement = () => {
  return (
    <>
      <ContentManagementWrapped>
        <div className="layout-content">
          <div className="row">
            <div className="col-lg-3 col-12">
              <SideBarTab />
            </div>
            <div className="col-lg-9 col-12">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="v-pills-home"
                  aria-labelledby="v-pills-home-tab"
                  role="tabpanel"
                >
                  <HomeContentPage />
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-profile"
                  role="tabpanel"
                  aria-labelledby="v-pills-profile-tab"
                >
                  <GalleryPage />
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-commercial"
                  role="tabpanel"
                  aria-labelledby="v-pills-commercial-tab"
                >
                  <CommercialSolarPage />
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-residential"
                  role="tabpanel"
                  aria-labelledby="v-pills-residential-tab"
                >
                  <ResidentialSolarPage />
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-solar-panels-page"
                  role="tabpanel"
                  aria-labelledby="v-pills-solar-panels-page-tab"
                >
                  <SolarPanelsPage />
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-battery-storage-page"
                  role="tabpanel"
                  aria-labelledby="v-pills-battery-storage-page-tab"
                >
                  <BatteryStoragePage />
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-tesla-power-wall-page"
                  role="tabpanel"
                  aria-labelledby="v-pills-tesla-power-wall-tab"
                >
                  <TeslaPowerWallPage />
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-shift-page"
                  role="tabpanel"
                  aria-labelledby="v-pills-shift-tab"
                >
                  <ShiftPage />
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-lightning-page"
                  role="tabpanel"
                  aria-labelledby="v-pills-lightning-tab"
                >
                  <LightningPage />
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-charging-page"
                  role="tabpanel"
                  aria-labelledby="v-pills-charging-tab"
                >
                  <ChargingPage />
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-get-quote-page"
                  role="tabpanel"
                  aria-labelledby="v-pills-get-quote-tab"
                >
                  <GetQuotePage />
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-plans-page"
                  role="tabpanel"
                  aria-labelledby="v-pills-plans-tab"
                >
                  <PlanPage />
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-about-us"
                  role="tabpanel"
                  aria-labelledby="v-pills-about-us-tab"
                >
                  <AboutPage />
                </div>
                <div
                  className="tab-pane fade"
                  id="terms"
                  role="tabpanel"
                  aria-labelledby="terms-tab"
                >
                  <TermsPage />
                </div>
                <div
                  className="tab-pane fade"
                  id="privacy"
                  role="tabpanel"
                  aria-labelledby="privacy-tab"
                >
                  <PrivacyPage />
                </div>
                <div
                  className="tab-pane fade"
                  id="cookie-policy"
                  role="tabpanel"
                  aria-labelledby="cookie-policy-tab"
                >
                  <CookiePolicyPage />
                </div>
                <div
                  className="tab-pane fade"
                  id="footer"
                  role="tabpanel"
                  aria-labelledby="footer-tab"
                >
                  <WebSiteFooter />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContentManagementWrapped>
    </>
  );
};

export default ContentManagement;
