import React from "react";
import { Row, Col, Typography } from "antd";
import { CounterCardStyle } from "./style";

const DashboardCounterCard = ({ count, title, subTitle, isSeeker }) => {
  const { Title } = Typography;
  return (
    <> 
      <CounterCardStyle>
        <div
          className="cricleboxGreen mb-4"
          style={{
            backgroundColor: isSeeker
              ? "rgba(51, 161, 97, 0.22)"
              : "rgba(141,236,72, 0.9)",
            border: isSeeker
              ? "rgba(51, 161, 97, 0.22)"
              : "rgba(141,236,72, 0.9)",
          }}
        >
          <div className="card_style">
            <Row align="middle" gutter={[24, 0]}>
              <Col>
                <Title
                  level={1}
                  className="count"
                  style={{ color: isSeeker ? "#33A161" : "#112E3C" }}
                >
                  {count}
                </Title>
              </Col>
              <Col>
                <Title
                  level={4}
                  className="title"
                  style={{ color: isSeeker ? "#33A161" : "#112E3C" }}
                >
                  {title}
                </Title>
                <span className="sub_title">{subTitle}</span>
              </Col>
            </Row>
          </div>
        </div>
      </CounterCardStyle>
    </>
  );
};
export default DashboardCounterCard;
