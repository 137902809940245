import React, { useState } from "react";
import {
  SystemConfigurationSideBarWrapped,
  SystemConfigurationWrapped,
} from "./style";
import { Menu } from "antd";
import {
  GET_PROJECTS_LIST_API,
  GET_SERVICES_LIST_API,
  GET_TESTIMONIALS_LIST_API,
} from "../../config/constants/endPoints";
import TestimonialConfiguration from "./Testimonial/TestimonialConfiguration";
import ServiceConfiguration from "./Service/ServiceConfiguration";
import ProjectConfiguration from "./Project/ProjectConfiguration";

const SystemConfiguration = () => {
  // const dispatch = useDispatch();
  const [dataList, setDataList] = useState([
    {
      id: 1,
      apiPath: GET_TESTIMONIALS_LIST_API,
      model_name: "Testimonials",
    },
    {
      id: 2,
      apiPath: GET_SERVICES_LIST_API,
      model_name: "Services",
    },
    {
      id: 3,
      apiPath: GET_PROJECTS_LIST_API,
      model_name: "Projects",
    },
  ]);

  const [selectedTile, setSelectedTile] = useState(1);

  const onItemClick = (data) => {
    setSelectedTile(data.id);
  };
  const Page = ({ currentPage }) => {
    return (
      <div>
        {currentPage === 1 && <TestimonialConfiguration />}
        {currentPage === 2 && <ServiceConfiguration />}
        {currentPage === 3 && <ProjectConfiguration />}
      </div>
    );
  };

  return (
    <>
      <SystemConfigurationWrapped>
        <div className="layout-content">
          <div className="row">
            <div className="col-lg-3 col-12">
              <SystemConfigurationSideBarWrapped>
                <div className="side-tabs">
                  <div>
                    <Menu
                      theme="light"
                      mode="inline"
                      defaultSelectedKeys={["0"]}
                    >
                      {dataList.map((systemConfigurationData, index) => (
                        <Menu.Item
                          key={index}
                          onClick={() => onItemClick(systemConfigurationData)}
                        >
                          <span className="label">
                            {systemConfigurationData.model_name}
                          </span>
                        </Menu.Item>
                      ))}
                    </Menu>
                  </div>
                </div>
              </SystemConfigurationSideBarWrapped>
            </div>
            <div className="col-lg-9 col-12">
              <div className="tab-content" id="v-pills-tabContent">
                <Page currentPage={selectedTile} />
              </div>
            </div>
          </div>
        </div>
      </SystemConfigurationWrapped>
      {/* )} */}
    </>
  );
};

export default SystemConfiguration;
