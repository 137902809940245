import { Form } from "antd";
import React, { useEffect, useState, } from "react";
import SaveButton from "../../components/button/saveButton";
import BaseInput from "../../components/form/BaseInput";
import { HomeWrapped } from "./style";
import { useDispatch, useSelector } from "react-redux";
import {
  getGalleryPageContentRequest,
  postGalleryPageContentRequest,
} from "../../redux/contentManagementSlice";
import { BannerImage, CONTENT_PAGES, STATUSES } from "../../config/constants/defaultValues";
import Loading from "../../components/shared/GeneralComponents";
import TextAreas from "../../components/form/TextAreas";
import SeoSection from "../../components/shared/seoSection";
import BannerImageCard from "../../components/shared/bannerImageCard";

const GalleryPage = () => {

  const dispatch = useDispatch();
  const { galleryPageData, galleryPageStatus, galleryPageError } =
    useSelector((state) => state.contentManagement);
  const [form] = Form.useForm();

  const [bannerSectionImageUrl, setBannerSectionImageUrl] = useState();
  const [bannerSectionImageFile, setBannerSectionImageFile] = useState();
  //Seo Section states
  const [ogImageUrl, setOGImageUrl] = useState();
  const [ogImageFile, setOGImageFile] = useState();
  const [twitterImageUrl, setTwitterImageUrl] = useState();
  const [twitterImageFile, setTwitterImageFile] = useState();

  useEffect(() => {
    dispatch(getGalleryPageContentRequest(CONTENT_PAGES.GALLERY_PAGE));
  }, [dispatch]);

  useEffect(() => {
    if (galleryPageData) {
      form.setFieldsValue({
        "detail[bannerSection][text]": galleryPageData?.contentPage?.detail?.bannerSection?.text,
        "detail[bannerSection][heading]": galleryPageData?.contentPage?.detail?.bannerSection?.heading,
        // Get Quote Section
        "detail[getQuoteSection][text]": galleryPageData?.contentPage?.detail?.getQuoteSection?.text,
        "detail[getQuoteSection][heading]": galleryPageData?.contentPage?.detail?.getQuoteSection?.heading,
        "detail[getQuoteSection][formHeading]": galleryPageData?.contentPage?.detail?.getQuoteSection?.formHeading,
        "detail[getQuoteSection][formConfirmationText]": galleryPageData?.contentPage?.detail?.getQuoteSection?.formConfirmationText,
        "detail[getQuoteSection][buttonText]": galleryPageData?.contentPage?.detail?.getQuoteSection?.buttonText,
        //Seo Values Set
        meta_title: galleryPageData?.contentPage?.seo_keywords?.meta_title ?? "",
        meta_description: galleryPageData?.contentPage?.seo_keywords?.meta_description ?? "",
        is_canonical: galleryPageData?.contentPage?.seo_keywords?.is_canonical === 1 ? true : false,
        robots_meta: galleryPageData?.contentPage?.seo_keywords?.robots_meta ?? "",
        meta_author: galleryPageData?.contentPage?.seo_keywords?.meta_author ?? "",
        og_type: galleryPageData?.contentPage?.seo_keywords?.og_type ?? "",
        og_title: galleryPageData?.contentPage?.seo_keywords?.og_title ?? "",
        og_description: galleryPageData?.contentPage?.seo_keywords?.og_description ?? "",
        og_image_width: galleryPageData?.contentPage?.seo_keywords?.og_image_width ?? "",
        og_image_height: galleryPageData?.contentPage?.seo_keywords?.og_image_height ?? "",
        og_url: galleryPageData?.contentPage?.seo_keywords?.og_url ?? "",
        og_site_name: galleryPageData?.contentPage?.seo_keywords?.og_site_name ?? "",
        og_locale: galleryPageData?.contentPage?.seo_keywords?.og_locale ?? "",
        og_locale_alternate: galleryPageData?.contentPage?.seo_keywords?.og_locale_alternate ?? "",
        twitter_card: galleryPageData?.contentPage?.seo_keywords?.twitter_card ?? "",
        twitter_site: galleryPageData?.contentPage?.seo_keywords?.twitter_site ?? "",
        twitter_title: galleryPageData?.contentPage?.seo_keywords?.twitter_title ?? "",
        twitter_description: galleryPageData?.contentPage?.seo_keywords?.twitter_description ?? "",
        twitter_url: galleryPageData?.contentPage?.seo_keywords?.twitter_url ?? "",
      });
      setBannerSectionImageUrl(galleryPageData?.contentPage?.contentImages?.bannerSectionImage ?? BannerImage);
      setTwitterImageUrl(galleryPageData?.contentPage?.seo_keywords?.twitter_image ?? BannerImage);
      setOGImageUrl(galleryPageData?.contentPage?.seo_keywords?.og_image ?? BannerImage);
    }
  }, [galleryPageData]);

  const onUpdate = (values) => {

    const data = {
      ...values,
      name: 'Gallery',
      "contentImages[bannerSectionImage]": bannerSectionImageFile ?? "",
      "og_image": ogImageFile ?? "",
      "twitter_image": twitterImageFile ?? "",
      "is_canonical": values.is_canonical === true ? 1 : 0,
      _method: 'PUT'
    }
    dispatch(postGalleryPageContentRequest({ data, id: galleryPageData?.contentPage?.id }))
  };

  return (
    <>
      {galleryPageStatus === STATUSES.LOADING ? (
        <Loading />
      ) : galleryPageStatus === STATUSES.ERROR ? (
        <div>{galleryPageError}</div>
      ) : (
        <HomeWrapped>
          <div className="row">
            <div className="col-sm-12">
              <Form
                layout="vertical"
                className="pt-3"
                form={form}
                onFinish={onUpdate}

              >
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-between align-items-start mb-4">
                    <h3 className="font-bold" >Gallery</h3>
                    <SaveButton title="Update" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                    {/* Banner Section */}
                    {/* <HeadingTitle title="Banner Section" /> */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Text"
                      name="detail[bannerSection][text]"
                      placeText="Banner Section Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Heading"
                      name="detail[bannerSection][heading]"
                      placeText="Banner Section Heading"
                    />
                    <BannerImageCard
                      title="Banner Section Image"
                      imageUrl={bannerSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setBannerSectionImageFile}
                      setImageUrl={setBannerSectionImageUrl}
                      imageText="Change Image"
                    />
                    {/* Get Quote section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Get Quote Section Text"
                      name="detail[getQuoteSection][text]"
                      placeText="Get Quote Section Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Get Quote Section Heading"
                      name="detail[getQuoteSection][heading]"
                      placeText="Get Quote Section Heading"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Get Quote Section Form Heading"
                      name="detail[getQuoteSection][formHeading]"
                      placeText="Get Quote Section Form Heading"
                    />
                    <TextAreas
                      labelText="Get Quote Section Form Confirmation Text"
                      name="detail[getQuoteSection][formConfirmationText]"
                      placeText="Get Quote Section Form Confirmation Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Get Quote Section Button Text"
                      name="detail[getQuoteSection][buttonText]"
                      placeText="Get Quote Section Button Text"
                    />
                  </div>
                  <SeoSection ogImageUrl={ogImageUrl}
                    setOGImageFile={setOGImageFile}
                    setOGImageUrl={setOGImageUrl}
                    twitterImageUrl={twitterImageUrl}
                    setTwitterImageFile={setTwitterImageFile}
                    setTwitterImageUrl={setTwitterImageUrl} />
                </div>
              </Form>
            </div>
          </div>
        </HomeWrapped>
      )}
    </>
  );
};

export default GalleryPage;
