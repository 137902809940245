import React, { useEffect, useState } from "react";
import { Form } from "antd";
import SaveButton from "../../components/button/saveButton";
import BaseInput from "../../components/form/BaseInput";
import { HomeWrapped } from "./style";
import { useDispatch, useSelector } from "react-redux";
import {
  getShiftPageContentRequest,
  postShiftPageContentRequest,
} from "../../redux/contentManagementSlice";
import {
  BannerImage,
  CONTENT_PAGES,
  STATUSES,
} from "../../config/constants/defaultValues";
import Loading from "../../components/shared/GeneralComponents";
import TextAreas from "../../components/form/TextAreas";
import SeoSection from "../../components/shared/seoSection";
import BannerImageCard from "../../components/shared/bannerImageCard";

const ShiftPage = () => {
  const dispatch = useDispatch();
  const { shiftPageData, shiftPageStatus, shiftPageError } = useSelector(
    (state) => state.contentManagement
  );
  const [form] = Form.useForm();
  // Banner Section
  const [bannerSectionImageUrl, setBannerSectionImageUrl] = useState();
  const [bannerSectionImageFile, setBannerSectionImageFile] = useState();
  // why Choose Section
  const [whyChooseSectionFirstImageUrl, setWhyChooseSectionFirstImageUrl] =
    useState();
  const [whyChooseSectionFirstImageFile, setWhyChooseSectionFirstImageFile] =
    useState();
  const [whyChooseSectionSecondImageUrl, setWhyChooseSectionSecondImageUrl] =
    useState();
  const [whyChooseSectionSecondImageFile, setWhyChooseSectionSecondImageFile] =
    useState();
  const [whyChooseSectionThirdImageUrl, setWhyChooseSectionThirdImageUrl] =
    useState();
  const [whyChooseSectionThirdImageFile, setWhyChooseSectionThirdImageFile] =
    useState();
  const [
    whyChooseSectionFeaturedImageUrl,
    setWhyChooseSectionFeaturedImageUrl,
  ] = useState();
  const [
    whyChooseSectionFeaturedImageFile,
    setWhyChooseSectionFeaturedImageFile,
  ] = useState();
  //Seo Section states
  const [ogImageUrl, setOGImageUrl] = useState();
  const [ogImageFile, setOGImageFile] = useState();
  const [twitterImageUrl, setTwitterImageUrl] = useState();
  const [twitterImageFile, setTwitterImageFile] = useState();

  useEffect(() => {
    dispatch(getShiftPageContentRequest(CONTENT_PAGES.SHIFT_PAGE));
  }, [dispatch]);

  useEffect(() => {
    if (shiftPageData) {
      form.setFieldsValue({
        "detail[bannerSection][text]":
          shiftPageData?.contentPage?.detail?.bannerSection?.text,
        "detail[bannerSection][heading]":
          shiftPageData?.contentPage?.detail?.bannerSection?.heading,
        "detail[bannerSection][description]":
          shiftPageData?.contentPage?.detail?.bannerSection?.description,
        "detail[bannerSection][firstBulletPoint]":
          shiftPageData?.contentPage?.detail?.bannerSection?.firstBulletPoint,
        "detail[bannerSection][secondBulletPoint]":
          shiftPageData?.contentPage?.detail?.bannerSection?.secondBulletPoint,
        "detail[bannerSection][thirdBulletPoint]":
          shiftPageData?.contentPage?.detail?.bannerSection?.thirdBulletPoint,
        "detail[bannerSection][buttonText]":
          shiftPageData?.contentPage?.detail?.bannerSection?.buttonText,
        // why Choose Section
        "detail[whyChooseSection][text]":
          shiftPageData?.contentPage?.detail?.whyChooseSection?.text,
        "detail[whyChooseSection][heading]":
          shiftPageData?.contentPage?.detail?.whyChooseSection?.heading,
        "detail[whyChooseSection][firstHeading]":
          shiftPageData?.contentPage?.detail?.whyChooseSection?.firstHeading,
        "detail[whyChooseSection][firstDescription]":
          shiftPageData?.contentPage?.detail?.whyChooseSection?.firstDescription,
        "detail[whyChooseSection][secondHeading]":
          shiftPageData?.contentPage?.detail?.whyChooseSection?.secondHeading,
        "detail[whyChooseSection][secondDescription]":
          shiftPageData?.contentPage?.detail?.whyChooseSection?.secondDescription,
        "detail[whyChooseSection][thirdHeading]":
          shiftPageData?.contentPage?.detail?.whyChooseSection?.thirdHeading,
        "detail[whyChooseSection][thirdDescription]":
          shiftPageData?.contentPage?.detail?.whyChooseSection?.thirdDescription,
        "detail[whyChooseSection][featuredHeading]":
          shiftPageData?.contentPage?.detail?.whyChooseSection?.featuredHeading,
        "detail[whyChooseSection][featuredDescription]":
          shiftPageData?.contentPage?.detail?.whyChooseSection
            ?.featuredDescription,
        // history Section
        "detail[historySection][text]":
          shiftPageData?.contentPage?.detail?.historySection?.text,
        "detail[historySection][heading]":
          shiftPageData?.contentPage?.detail?.historySection?.heading,
        "detail[historySection][firstCardHeading]":
          shiftPageData?.contentPage?.detail?.historySection?.firstCardHeading,
        "detail[historySection][firstCardDescription]":
          shiftPageData?.contentPage?.detail?.historySection
            ?.firstCardDescription,
        "detail[historySection][secondCardHeading]":
          shiftPageData?.contentPage?.detail?.historySection?.secondCardHeading,
        "detail[historySection][secondCardDescription]":
          shiftPageData?.contentPage?.detail?.historySection
            ?.secondCardDescription,
        "detail[historySection][thirdCardHeading]":
          shiftPageData?.contentPage?.detail?.historySection?.thirdCardHeading,
        "detail[historySection][thirdCardDescription]":
          shiftPageData?.contentPage?.detail?.historySection
            ?.thirdCardDescription,
        "detail[historySection][forthCardHeading]":
          shiftPageData?.contentPage?.detail?.historySection?.forthCardHeading,
        "detail[historySection][forthCardDescription]":
          shiftPageData?.contentPage?.detail?.historySection
            ?.forthCardDescription,
        // Get Quote Section
        "detail[getQuoteSection][text]":
          shiftPageData?.contentPage?.detail?.getQuoteSection?.text,
        "detail[getQuoteSection][heading]":
          shiftPageData?.contentPage?.detail?.getQuoteSection?.heading,
        "detail[getQuoteSection][formHeading]":
          shiftPageData?.contentPage?.detail?.getQuoteSection?.formHeading,
        "detail[getQuoteSection][formConfirmationText]":
          shiftPageData?.contentPage?.detail?.getQuoteSection
            ?.formConfirmationText,
        "detail[getQuoteSection][buttonText]":
          shiftPageData?.contentPage?.detail?.getQuoteSection?.buttonText,
        //Seo Values Set
        meta_title: shiftPageData?.contentPage?.seo_keywords?.meta_title ?? "",
        meta_description:
          shiftPageData?.contentPage?.seo_keywords?.meta_description ?? "",
        is_canonical:
          shiftPageData?.contentPage?.seo_keywords?.is_canonical === 1
            ? true
            : false,
        robots_meta:
          shiftPageData?.contentPage?.seo_keywords?.robots_meta ?? "",
        meta_author:
          shiftPageData?.contentPage?.seo_keywords?.meta_author ?? "",
        og_type: shiftPageData?.contentPage?.seo_keywords?.og_type ?? "",
        og_title: shiftPageData?.contentPage?.seo_keywords?.og_title ?? "",
        og_description:
          shiftPageData?.contentPage?.seo_keywords?.og_description ?? "",
        og_image_width:
          shiftPageData?.contentPage?.seo_keywords?.og_image_width ?? "",
        og_image_height:
          shiftPageData?.contentPage?.seo_keywords?.og_image_height ?? "",
        og_url: shiftPageData?.contentPage?.seo_keywords?.og_url ?? "",
        og_site_name:
          shiftPageData?.contentPage?.seo_keywords?.og_site_name ?? "",
        og_locale: shiftPageData?.contentPage?.seo_keywords?.og_locale ?? "",
        og_locale_alternate:
          shiftPageData?.contentPage?.seo_keywords?.og_locale_alternate ?? "",
        twitter_card:
          shiftPageData?.contentPage?.seo_keywords?.twitter_card ?? "",
        twitter_site:
          shiftPageData?.contentPage?.seo_keywords?.twitter_site ?? "",
        twitter_title:
          shiftPageData?.contentPage?.seo_keywords?.twitter_title ?? "",
        twitter_description:
          shiftPageData?.contentPage?.seo_keywords?.twitter_description ?? "",
        twitter_url:
          shiftPageData?.contentPage?.seo_keywords?.twitter_url ?? "",
      });
      setBannerSectionImageUrl(
        shiftPageData?.contentPage?.contentImages?.bannerSectionImage ??
          BannerImage
      );
      setWhyChooseSectionFirstImageUrl(
        shiftPageData?.contentPage?.contentImages?.whyChooseSectionFirstImage ??
          BannerImage
      );
      setWhyChooseSectionSecondImageUrl(
        shiftPageData?.contentPage?.contentImages
          ?.whyChooseSectionSecondImage ?? BannerImage
      );
      setWhyChooseSectionThirdImageUrl(
        shiftPageData?.contentPage?.contentImages?.whyChooseSectionThirdImage ??
          BannerImage
      );
      setWhyChooseSectionFeaturedImageUrl(
        shiftPageData?.contentPage?.contentImages
          ?.whyChooseSectionFeaturedImage ?? BannerImage
      );
      setTwitterImageUrl(
        shiftPageData?.contentPage?.seo_keywords?.twitter_image ?? BannerImage
      );
      setOGImageUrl(
        shiftPageData?.contentPage?.seo_keywords?.og_image ?? BannerImage
      );
    }
  }, [shiftPageData]);

  const onUpdate = (values) => {
    const data = {
      ...values,
      name: "Shift Page",
      og_image: ogImageFile ?? "",
      twitter_image: twitterImageFile ?? "",
      is_canonical: values.is_canonical === true ? 1 : 0,
      "contentImages[bannerSectionImage]": bannerSectionImageFile ?? "",
      "contentImages[whyChooseSectionFirstImage]":
        whyChooseSectionFirstImageFile ?? "",
      "contentImages[whyChooseSectionSecondImage]":
        whyChooseSectionSecondImageFile ?? "",
      "contentImages[whyChooseSectionThirdImage]":
        whyChooseSectionThirdImageFile ?? "",
      "contentImages[whyChooseSectionFeaturedImage]":
        whyChooseSectionFeaturedImageFile ?? "",

      _method: "PUT",
    };
    dispatch(
      postShiftPageContentRequest({ data, id: shiftPageData?.contentPage?.id })
    );
  };

  return (
    <>
      {shiftPageStatus === STATUSES.LOADING ? (
        <Loading />
      ) : shiftPageStatus === STATUSES.ERROR ? (
        <div>{shiftPageError}</div>
      ) : (
        <HomeWrapped>
          <div className="row">
            <div className="col-sm-12">
              <Form
                layout="vertical"
                className="pt-3"
                form={form}
                onFinish={onUpdate}
              >
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-between align-items-start mb-4">
                    <h3 className="font-bold">Shift Page</h3>
                    <SaveButton title="Update" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                    {/* Banner Section */}
                    {/* <HeadingTitle title="Banner Section" /> */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Text"
                      name="detail[bannerSection][text]"
                      placeText="Banner Section Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Heading"
                      name="detail[bannerSection][heading]"
                      placeText="Banner Section Heading"
                    />
                    <TextAreas
                      labelText="Banner Section Description"
                      name="detail[bannerSection][description]"
                      placeText="Banner Section Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section First Bullet Point"
                      name="detail[bannerSection][firstBulletPoint]"
                      placeText="Banner Section First Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Second Bullet Point"
                      name="detail[bannerSection][secondBulletPoint]"
                      placeText="Banner Section Second Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Third Bullet Point"
                      name="detail[bannerSection][thirdBulletPoint]"
                      placeText="Banner Section Third Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Button Text"
                      name="detail[bannerSection][buttonText]"
                      placeText="Banner Section Button Text"
                    />
                    <BannerImageCard
                      title="Banner Section Image"
                      imageUrl={bannerSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setBannerSectionImageFile}
                      setImageUrl={setBannerSectionImageUrl}
                      imageText="Change Image"
                    />
                    {/* Why Choose section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Why Choose Section Section Text"
                      name="detail[whyChooseSection][text]"
                      placeText="Why Choose Section Section Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Why Choose Section Section Heading"
                      name="detail[whyChooseSection][heading]"
                      placeText="Why Choose Section Section Heading"
                    />
                    <BannerImageCard
                      title="Why Choose Section First Image"
                      imageUrl={whyChooseSectionFirstImageUrl}
                      isBlogImage={true}
                      setImageFile={setWhyChooseSectionFirstImageFile}
                      setImageUrl={setWhyChooseSectionFirstImageUrl}
                      imageText="Change Image"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Why Choose Section First Heading"
                      name="detail[whyChooseSection][firstHeading]"
                      placeText="Why Choose Section First Heading"
                    />
                    <TextAreas
                      labelText="Why Choose Section First Description"
                      name="detail[whyChooseSection][firstDescription]"
                      placeText="Why Choose Section First Description"
                    />
                    <BannerImageCard
                      title="Why Choose Section Second Image"
                      imageUrl={whyChooseSectionSecondImageUrl}
                      isBlogImage={true}
                      setImageFile={setWhyChooseSectionSecondImageFile}
                      setImageUrl={setWhyChooseSectionSecondImageUrl}
                      imageText="Change Image"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Why Choose Section Second Heading"
                      name="detail[whyChooseSection][secondHeading]"
                      placeText="Why Choose Section Second Heading"
                    />
                    <TextAreas
                      labelText="Why Choose Section Second Description"
                      name="detail[whyChooseSection][secondDescription]"
                      placeText="Why Choose Section Second Description"
                    />
                    <BannerImageCard
                      title="Why Choose Section Third Image"
                      imageUrl={whyChooseSectionThirdImageUrl}
                      isBlogImage={true}
                      setImageFile={setWhyChooseSectionThirdImageFile}
                      setImageUrl={setWhyChooseSectionThirdImageUrl}
                      imageText="Change Image"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Why Choose Section Third Heading"
                      name="detail[whyChooseSection][thirdHeading]"
                      placeText="Why Choose Section Third Heading"
                    />
                    <TextAreas
                      labelText="Why Choose Section Third Description"
                      name="detail[whyChooseSection][thirdDescription]"
                      placeText="Why Choose Section Third Description"
                    />
                    <BannerImageCard
                      title="Why Choose Section Featured Image"
                      imageUrl={whyChooseSectionFeaturedImageUrl}
                      isBlogImage={true}
                      setImageFile={setWhyChooseSectionFeaturedImageFile}
                      setImageUrl={setWhyChooseSectionFeaturedImageUrl}
                      imageText="Change Image"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Why Choose Section Featured Heading"
                      name="detail[whyChooseSection][featuredHeading]"
                      placeText="Why Choose Section Featured Heading"
                    />
                    <TextAreas
                      labelText="Why Choose Section Featured Description"
                      name="detail[whyChooseSection][featuredDescription]"
                      placeText="Why Choose Section Featured Description"
                    />
                    {/* History Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="History Section Text"
                      name="detail[historySection][text]"
                      placeText="Career Section Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="History Section Heading"
                      name="detail[historySection][heading]"
                      placeText="Career Section Heading"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="History Section First Card Heading"
                      name="detail[historySection][firstCardHeading]"
                      placeText="History Section First Card Heading"
                    />
                    <TextAreas
                      labelText="History Section First Card Description"
                      name="detail[historySection][firstCardDescription]"
                      placeText="History Section First Card Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="History Section Second Card Heading"
                      name="detail[historySection][secondCardHeading]"
                      placeText="History Section Second Card Heading"
                    />
                    <TextAreas
                      labelText="History Section Second Card Description"
                      name="detail[historySection][secondCardDescription]"
                      placeText="History Section Second Card Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="History Section Third Card Heading"
                      name="detail[historySection][thirdCardHeading]"
                      placeText="History Section Third Card Heading"
                    />
                    <TextAreas
                      labelText="History Section Third Card Description"
                      name="detail[historySection][thirdCardDescription]"
                      placeText="History Section Third Card Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="History Section Forth Card Heading"
                      name="detail[historySection][forthCardHeading]"
                      placeText="History Section Forth Card Heading"
                    />
                    <TextAreas
                      labelText="History Section Forth Card Description"
                      name="detail[historySection][forthCardDescription]"
                      placeText="History Section Forth Card Description"
                    />
                    {/* Get Quote section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Get Quote Section Text"
                      name="detail[getQuoteSection][text]"
                      placeText="Get Quote Section Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Get Quote Section Heading"
                      name="detail[getQuoteSection][heading]"
                      placeText="Get Quote Section Heading"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Get Quote Section Form Heading"
                      name="detail[getQuoteSection][formHeading]"
                      placeText="Get Quote Section Form Heading"
                    />
                    <TextAreas
                      labelText="Get Quote Section Form Confirmation Text"
                      name="detail[getQuoteSection][formConfirmationText]"
                      placeText="Get Quote Section Form Confirmation Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Get Quote Section Button Text"
                      name="detail[getQuoteSection][buttonText]"
                      placeText="Get Quote Section Button Text"
                    />
                  </div>
                  <SeoSection
                    ogImageUrl={ogImageUrl}
                    setOGImageFile={setOGImageFile}
                    setOGImageUrl={setOGImageUrl}
                    twitterImageUrl={twitterImageUrl}
                    setTwitterImageFile={setTwitterImageFile}
                    setTwitterImageUrl={setTwitterImageUrl}
                  />
                </div>
              </Form>
            </div>
          </div>
        </HomeWrapped>
      )}
    </>
  );
};

export default ShiftPage;
