import { createGlobalStyle } from "styled-components";
import theme from "./config/theme/themeVariables";

const GlobalStyle = createGlobalStyle`
    *,*::before,*::after{
        margin: 0;
        padding: 0;
    }
    /* h1, h2, h3, h4, h5, h6, p{
        color: black;
    } */
    body {
        font-family: 'Poppins' !important;
        font-style: normal !important;
        overflow-x: hidden;
        background: #F5F5F5;
    }
    .primary_color {
        color: ${theme.primaryColor};
    }
    .secondary_color {
        color: ${theme.secondaryColor};
    }
    .text_light_color {
        color: ${theme.textColors.textLightColor};
    }
    .text_lightest_color {
        color: ${theme.textColors.textLightestColor};
    }
    .text_lightGray_color {
        color: ${theme.textColors.lightGrayColor};
    }
    .radiu_base {
        border-radius: ${theme.shape.radiusBase};
    }
    .border_color {
        border: 1px solid ${theme.border.color};
    }
    .ant-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600 !important;
    }
    .ant-image {
        height: 100%;
        width: 100%;
    }
    .ant-input:hover,
    .ant-input:focus,
    .ant-input-focused,
    .ant-input-affix-wrapper:hover,
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused,
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,.ant-select:not(
    .ant-select-disabled):hover .ant-select-selector,
    .ant-picker:hover, .ant-picker-focused {
        box-shadow: unset !important;
        border-color: #909090 !important;
    }
    .ant-form-item-has-error .ant-input, .ant-form-item-has-error .ant-input-affix-wrapper, .ant-form-item-has-error .ant-input:hover,
    .ant-form-item-has-error .ant-input-affix-wrapper:hover {
        border-color: #ff4d4f !important;
    }
    .ant-form-padding{
        padding: 0 !important;
    }
    .accordion-item:first-of-type .accordion-button {
        background: #dedbe529;
        padding: 10px 20px;
        color: #112E3C;
    }
    .accordion-button:not(.collapsed) {
        background-color: #B6AFC1 !important;
        color: #112E3C !important;
    }
    .accordion-button:focus {
        box-shadow: none !important;
    }
    .bar-chart {
        height: 350px !important;
        // background: rgb(94,76,122);
        // background: linear-gradient(97deg, rgba(94,76,122,1) 0%, rgba(107,73,159,1) 42%, rgba(67,42,103,1) 64%, rgba(51,34,75,1) 100%);
    }
    .ant-modal{
       width: 900px !important;
       border-radius: 10px;
    }
    .ant-modal-body{
        border-radius: 10px;
        padding: 0; 
        // background: #34195aeb;
    }
  
    .ant-modal-content {
        width: 950px;
        margin: auto;
        border-radius: 10px;
        // box-shadow: rgba(55, 35, 84, 0.2) 0px 0px 0px 3px; 
        @media (max-width: 912px) {
          width: 80%;
        }
        @media (max-width: 767px) {
             width: 90%;
        }
    }
    .user-profile{
        h3{
            color: #14211C;
            font-size: 24px;
            font-weight: 600;
            line-height: 36px;
        }
        p{
            color: #14211C;
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
        }
        @media (max-width: 767px) {
            h3{
                font-size: 20px;
            }
            p{
                font-size: 16px;
            }
        }
    }
   .modal-heading{
    color: #33A161;
            font-size: 20px;
            font-family: Poppins;
            font-weight: 600;
            line-height: 30px;
            word-wrap: break-word;
   }
    .Applied-box{
        box-shadow: 0px 2.767256498336792px 2.2138051986694336px rgba(0, 0, 0, 0.07); 
        border-radius: 10px;
        border: 1px #D8D8D8 solid;
        padding: 20px;
       
        span{
            color: #14211C;
            font-size: 18px;
            font-family: Poppins;
            font-weight: 600;
            line-height: 27px;
            word-wrap: break-word;
            
        }
        p{
            color: #14211C;
            font-size: 16px;
            font-family: Poppins;
            font-weight: 400;
            line-height: 24px;
            word-wrap: break-word;
        }
        .applied-btn{
            color: #FAFAFB;
            font-size: 18px;
            font-weight: 500;
            line-height: 20px;
            background:#33A161;
            border: none;
            padding:10px 14px;
            border-radius: 6px;
        }
    }

    .user-data{
            margin-bottom: 30px;
            margin-top:30px;
        h6{
            color: #14211C;
            font-size: 16px;
            font-family: Poppins;
            font-weight: 600;
            word-wrap: break-word;
            margin-bottom: 10px;
        }
        p{
            color: #14211C;
            font-size: 20px;
            font-family: Poppins;
            font-weight: 400;
            line-height: 30px;
            word-wrap: break-word;
            margin-bottom: 0;
        }
       &:last-child{
        margin-bottom : 0;
       }
        .download-icon{
            width: 40px;
            height: 40px;
            background: #112E3C;
            display: flex;
            padding: 8px;
            cursor: pointer;
            border-radius: 6px;
        }
        @media (max-width: 912px) {
                margin-bottom: 0px !important;
                margin-top:20px !important;
                p{
                    font-size: 14px !important;
                }
          }
    }

    .profile-dropdown{
        position: relative;
        button{
          background: transparent;
          border:none;
        }
        i{
            font-size: 12px;
        }
        ul{
            li{
                cursor: pointer;
            }
        }
      }
      .dropdown-toggle::after {
        display: none;
    }    
      .dropdown-menu {
        display: block;
        position: absolute;
        top: 100%;
        right: 0;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        max-height: 0;
        height: 0px;
        visibility: hidden;
        opacity: 0;
        overflow: hidden;
        transition: 0.5s ease;
      }
      
      .dropdown-menu.open {
        max-height: 35px;
        min-height: 35px;
        height: 35px;
        opacity: 1;
        visibility: visible;
        padding: 5px 0;
      }
      
      .dropdown-menu.close {
        max-height: 0;
        padding: 0;
      }

`;


export default GlobalStyle;
