import "antd/dist/antd.css";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import GlobalStyle from "./GlobalStyle";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import Main from "./components/layout/Main";
import AppLogout from "./components/logout/appLogout.js";
import { USER_TYPE } from "./config/constants/defaultValues";
import { ProtectedRoute } from "./config/services/protectedRoute.js";
import Home from "./pages/Home";
import SignIn from "./pages/SignIn";
import ContentManagement from "./pages/contentManagement/content-management";
import SystemConfiguration from "./pages/systemConfiguration/systemConfiguration";
import EmailContactFormList from "./pages/emailContact/emailContactForm.js";
import ContactFormList from "./pages/contactForm/contactFormList.js";
import EstimateFormList from "./pages/estimateForm/estimateFormList.js";

function App() {
  return (
    <>
      <GlobalStyle />
      <div className="App">
        {
          <Routes>
            <Route path="/login" element={<SignIn />} />
            <Route path="*" element={<Dashboard />} />
          </Routes>
        }
      </div>
    </>
  );
}

function Dashboard() {
  const { userType } = useSelector((state) => state.auth);

  return (
    <AppLogout>
      <Main>
        <Routes>
          <Route
            path="/*"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />

          {(userType === USER_TYPE.SUPER_ADMIN || userType === USER_TYPE.JUMP_ADMIN) && <>
            <Route
              path="/contact-form-list"
              element={
                <ProtectedRoute>
                  <ContactFormList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/email-contact-list"
              element={
                <ProtectedRoute>
                  <EmailContactFormList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/estimate-form-list"
              element={
                <ProtectedRoute>
                  <EstimateFormList />
                </ProtectedRoute>
              }
            />
          </>}
          {(userType === USER_TYPE.SUPER_ADMIN || userType === USER_TYPE.SYSTEM_ADMIN) && <>
            <Route
              path="/content-management"
              element={
                <ProtectedRoute>
                  <ContentManagement />
                </ProtectedRoute>
              }
            />
            <Route
              path="/system-configuration"
              element={
                <ProtectedRoute>
                  <SystemConfiguration />
                </ProtectedRoute>
              }
            />
          </>}
        </Routes>
      </Main>
    </AppLogout>
  );
}

export default App;
