import { Form } from "antd";
import React, { useEffect, useState } from "react";
import BaseInput from "../../components/form/BaseInput";
import { ContentWrapped } from "./style";
import SaveButton from "../../components/button/saveButton";
import { useDispatch, useSelector } from "react-redux";
import {
  getPlanPageContentRequest,
  postPlanPageContentRequest,
} from "../../redux/contentManagementSlice";
import Loading from "../../components/shared/GeneralComponents";
import { BannerImage, CONTENT_PAGES, STATUSES } from "../../config/constants/defaultValues";
import TextAreas from "../../components/form/TextAreas";
import SeoSection from "../../components/shared/seoSection";
import BannerImageCard from "../../components/shared/bannerImageCard";

const PlanPage = () => {
  const dispatch = useDispatch();
  const { planPageData, planPageStatus, planPageError } = useSelector(
    (state) => state.contentManagement
  );
  const [form] = Form.useForm();
  // Banner Section
  const [bannerSectionImageUrl, setBannerSectionImageUrl] = useState();
  const [bannerSectionImageFile, setBannerSectionImageFile] = useState();
  // Fifth Section
  const [fifthSectionImageUrl, setFifthSectionImageUrl] = useState();
  const [fifthSectionImageFile, setFifthSectionImageFile] = useState();
  //Seo Section states
  const [ogImageUrl, setOGImageUrl] = useState();
  const [ogImageFile, setOGImageFile] = useState();
  const [twitterImageUrl, setTwitterImageUrl] = useState();
  const [twitterImageFile, setTwitterImageFile] = useState();

  useEffect(() => {
    dispatch(getPlanPageContentRequest(CONTENT_PAGES.PLAN_PAGE));
  }, [dispatch]);

  useEffect(() => {
    if (planPageData) {
      form.setFieldsValue({
        "detail[bannerSection][text]": planPageData?.contentPage?.detail?.bannerSection?.text,
        "detail[bannerSection][heading]": planPageData?.contentPage?.detail?.bannerSection?.heading,
        "detail[bannerSection][description]":planPageData?.contentPage?.detail?.bannerSection?.description,
        // Plan Section
        "detail[planSection][heading]": planPageData?.contentPage?.detail?.planSection?.heading,
        "detail[planSection][description]":planPageData?.contentPage?.detail?.planSection?.description,
        // First Card
        "detail[planSection][firstCardHeading]": planPageData?.contentPage?.detail?.planSection?.firstCardHeading,
        "detail[planSection][firstCardDescription]": planPageData?.contentPage?.detail?.planSection?.firstCardDescription,
        "detail[planSection][firstCardButtonText]": planPageData?.contentPage?.detail?.planSection?.firstCardButtonText,
        "detail[planSection][firstCardBenefitsHeading]": planPageData?.contentPage?.detail?.planSection?.firstCardBenefitsHeading,
        "detail[planSection][firstCardBenefits][firstBulletPoint]": planPageData?.contentPage?.detail?.planSection?.firstCardBenefits?.firstBulletPoint,
        "detail[planSection][firstCardBenefits][secondBulletPoint]": planPageData?.contentPage?.detail?.planSection?.firstCardBenefits?.secondBulletPoint,
        "detail[planSection][firstCardBenefits][thirdBulletPoint]": planPageData?.contentPage?.detail?.planSection?.firstCardBenefits?.thirdBulletPoint,
        "detail[planSection][firstCardBenefits][forthBulletPoint]": planPageData?.contentPage?.detail?.planSection?.firstCardBenefits?.forthBulletPoint,
        "detail[planSection][firstCardBenefits][fifthBulletPoint]": planPageData?.contentPage?.detail?.planSection?.firstCardBenefits?.fifthBulletPoint,
        "detail[planSection][firstCardPaymentsHeading]": planPageData?.contentPage?.detail?.planSection?.firstCardPaymentsHeading,
        "detail[planSection][firstCardPayments][firstBulletPoint]": planPageData?.contentPage?.detail?.planSection?.firstCardPayments?.firstBulletPoint,
        "detail[planSection][firstCardPayments][secondBulletPoint]": planPageData?.contentPage?.detail?.planSection?.firstCardPayments?.secondBulletPoint,
        "detail[planSection][firstCardOwnershipHeading]": planPageData?.contentPage?.detail?.planSection?.firstCardOwnershipHeading,
        "detail[planSection][firstCardOwnership][firstBulletPoint]": planPageData?.contentPage?.detail?.planSection?.firstCardOwnership?.firstBulletPoint,
        // Second Card
        "detail[planSection][secondCardHeading]": planPageData?.contentPage?.detail?.planSection?.secondCardHeading,
        "detail[planSection][secondCardDescription]": planPageData?.contentPage?.detail?.planSection?.secondCardDescription,
        "detail[planSection][secondCardButtonText]": planPageData?.contentPage?.detail?.planSection?.secondCardButtonText,
        "detail[planSection][secondCardBenefitsHeading]": planPageData?.contentPage?.detail?.planSection?.secondCardBenefitsHeading,
        "detail[planSection][secondCardBenefits][firstBulletPoint]": planPageData?.contentPage?.detail?.planSection?.secondCardBenefits?.firstBulletPoint,
        "detail[planSection][secondCardBenefits][secondBulletPoint]": planPageData?.contentPage?.detail?.planSection?.secondCardBenefits?.secondBulletPoint,
        "detail[planSection][secondCardBenefits][thirdBulletPoint]": planPageData?.contentPage?.detail?.planSection?.secondCardBenefits?.thirdBulletPoint,
        "detail[planSection][secondCardBenefits][forthBulletPoint]": planPageData?.contentPage?.detail?.planSection?.secondCardBenefits?.forthBulletPoint,
        "detail[planSection][secondCardBenefits][fifthBulletPoint]": planPageData?.contentPage?.detail?.planSection?.secondCardBenefits?.fifthBulletPoint,
        "detail[planSection][secondCardPaymentsHeading]": planPageData?.contentPage?.detail?.planSection?.secondCardPaymentsHeading,
        "detail[planSection][secondCardPayments][firstBulletPoint]": planPageData?.contentPage?.detail?.planSection?.secondCardPayments?.firstBulletPoint,
        "detail[planSection][secondCardPayments][secondBulletPoint]": planPageData?.contentPage?.detail?.planSection?.secondCardPayments?.secondBulletPoint,
        "detail[planSection][secondCardOwnershipHeading]": planPageData?.contentPage?.detail?.planSection?.secondCardOwnershipHeading,
        "detail[planSection][secondCardOwnership][firstBulletPoint]": planPageData?.contentPage?.detail?.planSection?.secondCardOwnership?.firstBulletPoint,
        // Third Card
        "detail[planSection][thirdCardHeading]": planPageData?.contentPage?.detail?.planSection?.thirdCardHeading,
        "detail[planSection][thirdCardDescription]": planPageData?.contentPage?.detail?.planSection?.thirdCardDescription,
        "detail[planSection][thirdCardButtonText]": planPageData?.contentPage?.detail?.planSection?.thirdCardButtonText,
        "detail[planSection][thirdCardBenefitsHeading]": planPageData?.contentPage?.detail?.planSection?.thirdCardBenefitsHeading,
        "detail[planSection][thirdCardBenefits][firstBulletPoint]": planPageData?.contentPage?.detail?.planSection?.thirdCardBenefits?.firstBulletPoint,
        "detail[planSection][thirdCardBenefits][secondBulletPoint]": planPageData?.contentPage?.detail?.planSection?.thirdCardBenefits?.secondBulletPoint,
        "detail[planSection][thirdCardBenefits][thirdBulletPoint]": planPageData?.contentPage?.detail?.planSection?.thirdCardBenefits?.thirdBulletPoint,
        "detail[planSection][thirdCardBenefits][forthBulletPoint]": planPageData?.contentPage?.detail?.planSection?.thirdCardBenefits?.forthBulletPoint,
        "detail[planSection][thirdCardBenefits][fifthBulletPoint]": planPageData?.contentPage?.detail?.planSection?.thirdCardBenefits?.fifthBulletPoint,
        "detail[planSection][thirdCardPaymentsHeading]": planPageData?.contentPage?.detail?.planSection?.thirdCardPaymentsHeading,
        "detail[planSection][thirdCardPayments][firstBulletPoint]": planPageData?.contentPage?.detail?.planSection?.thirdCardPayments?.firstBulletPoint,
        "detail[planSection][thirdCardPayments][secondBulletPoint]": planPageData?.contentPage?.detail?.planSection?.thirdCardPayments?.secondBulletPoint,
        "detail[planSection][thirdCardOwnershipHeading]": planPageData?.contentPage?.detail?.planSection?.thirdCardOwnershipHeading,
        "detail[planSection][thirdCardOwnership][firstBulletPoint]": planPageData?.contentPage?.detail?.planSection?.thirdCardOwnership?.firstBulletPoint,
        // Pros & Cons
        "detail[inquiringSection][text]": planPageData?.contentPage?.detail?.inquiringSection?.text,
        "detail[inquiringSection][heading]": planPageData?.contentPage?.detail?.inquiringSection?.heading,
        "detail[inquiringSection][prosHeading]": planPageData?.contentPage?.detail?.inquiringSection?.prosHeading,
        "detail[inquiringSection][consHeading]": planPageData?.contentPage?.detail?.inquiringSection?.consHeading,
        // Lease
        "detail[inquiringSection][loanHeading]": planPageData?.contentPage?.detail?.inquiringSection?.loanHeading,
        "detail[inquiringSection][loanPros][firstBulletPoint]": planPageData?.contentPage?.detail?.inquiringSection?.loanPros?.firstBulletPoint,
        "detail[inquiringSection][loanPros][secondBulletPoint]": planPageData?.contentPage?.detail?.inquiringSection?.loanPros?.secondBulletPoint,
        "detail[inquiringSection][loanPros][thirdBulletPoint]": planPageData?.contentPage?.detail?.inquiringSection?.loanPros?.thirdBulletPoint,
        "detail[inquiringSection][loanPros][forthBulletPoint]": planPageData?.contentPage?.detail?.inquiringSection?.loanPros?.forthBulletPoint,
        "detail[inquiringSection][loanPros][fifthBulletPoint]": planPageData?.contentPage?.detail?.inquiringSection?.loanPros?.fifthBulletPoint,
        "detail[inquiringSection][loanCons][firstBulletPoint]": planPageData?.contentPage?.detail?.inquiringSection?.loanCons?.firstBulletPoint,
        "detail[inquiringSection][loanCons][secondBulletPoint]": planPageData?.contentPage?.detail?.inquiringSection?.loanCons?.secondBulletPoint,
        "detail[inquiringSection][loanCons][thirdBulletPoint]": planPageData?.contentPage?.detail?.inquiringSection?.loanCons?.thirdBulletPoint,
        "detail[inquiringSection][loanCons][forthBulletPoint]": planPageData?.contentPage?.detail?.inquiringSection?.loanCons?.forthBulletPoint,
        "detail[inquiringSection][loanCons][fifthBulletPoint]": planPageData?.contentPage?.detail?.inquiringSection?.loanCons?.fifthBulletPoint,
        // Lease
        "detail[inquiringSection][leaseHeading]": planPageData?.contentPage?.detail?.inquiringSection?.leaseHeading,
        "detail[inquiringSection][leasePros][firstBulletPoint]": planPageData?.contentPage?.detail?.inquiringSection?.leasePros?.firstBulletPoint,
        "detail[inquiringSection][leasePros][secondBulletPoint]": planPageData?.contentPage?.detail?.inquiringSection?.leasePros?.secondBulletPoint,
        "detail[inquiringSection][leasePros][thirdBulletPoint]": planPageData?.contentPage?.detail?.inquiringSection?.leasePros?.thirdBulletPoint,
        "detail[inquiringSection][leasePros][forthBulletPoint]": planPageData?.contentPage?.detail?.inquiringSection?.leasePros?.forthBulletPoint,
        "detail[inquiringSection][leasePros][fifthBulletPoint]": planPageData?.contentPage?.detail?.inquiringSection?.leasePros?.fifthBulletPoint,
        "detail[inquiringSection][leaseCons][firstBulletPoint]": planPageData?.contentPage?.detail?.inquiringSection?.leaseCons?.firstBulletPoint,
        "detail[inquiringSection][leaseCons][secondBulletPoint]": planPageData?.contentPage?.detail?.inquiringSection?.leaseCons?.secondBulletPoint,
        "detail[inquiringSection][leaseCons][thirdBulletPoint]": planPageData?.contentPage?.detail?.inquiringSection?.leaseCons?.thirdBulletPoint,
        "detail[inquiringSection][leaseCons][forthBulletPoint]": planPageData?.contentPage?.detail?.inquiringSection?.leaseCons?.forthBulletPoint,
        "detail[inquiringSection][leaseCons][fifthBulletPoint]": planPageData?.contentPage?.detail?.inquiringSection?.leaseCons?.fifthBulletPoint,
        // Purchase
        "detail[inquiringSection][purchaseHeading]": planPageData?.contentPage?.detail?.inquiringSection?.purchaseHeading,
        "detail[inquiringSection][purchasePros][firstBulletPoint]": planPageData?.contentPage?.detail?.inquiringSection?.purchasePros?.firstBulletPoint,
        "detail[inquiringSection][purchasePros][secondBulletPoint]": planPageData?.contentPage?.detail?.inquiringSection?.purchasePros?.secondBulletPoint,
        "detail[inquiringSection][purchasePros][thirdBulletPoint]": planPageData?.contentPage?.detail?.inquiringSection?.purchasePros?.thirdBulletPoint,
        "detail[inquiringSection][purchasePros][forthBulletPoint]": planPageData?.contentPage?.detail?.inquiringSection?.purchasePros?.forthBulletPoint,
        "detail[inquiringSection][purchasePros][fifthBulletPoint]": planPageData?.contentPage?.detail?.inquiringSection?.purchasePros?.fifthBulletPoint,
        "detail[inquiringSection][purchaseCons][firstBulletPoint]": planPageData?.contentPage?.detail?.inquiringSection?.purchaseCons?.firstBulletPoint,
        "detail[inquiringSection][purchaseCons][secondBulletPoint]": planPageData?.contentPage?.detail?.inquiringSection?.purchaseCons?.secondBulletPoint,
        "detail[inquiringSection][purchaseCons][thirdBulletPoint]": planPageData?.contentPage?.detail?.inquiringSection?.purchaseCons?.thirdBulletPoint,
        "detail[inquiringSection][purchaseCons][forthBulletPoint]": planPageData?.contentPage?.detail?.inquiringSection?.purchaseCons?.forthBulletPoint,
        "detail[inquiringSection][purchaseCons][fifthBulletPoint]": planPageData?.contentPage?.detail?.inquiringSection?.purchaseCons?.fifthBulletPoint,
        // Testimonials
        "detail[testimonialsSection][text]": planPageData?.contentPage?.detail?.testimonialsSection?.text,
        "detail[testimonialsSection][heading]": planPageData?.contentPage?.detail?.testimonialsSection?.heading,
        // Fifth Section
        "detail[fifthSection][heading]": planPageData?.contentPage?.detail?.fifthSection?.heading,
        "detail[fifthSection][description]": planPageData?.contentPage?.detail?.fifthSection?.description,
      //Seo Values Set
      meta_title: planPageData?.contentPage?.seo_keywords?.meta_title ?? "",
      meta_description: planPageData?.contentPage?.seo_keywords?.meta_description ?? "",
      is_canonical: planPageData?.contentPage?.seo_keywords?.is_canonical === 1 ? true : false,
      robots_meta: planPageData?.contentPage?.seo_keywords?.robots_meta ?? "",
      meta_author: planPageData?.contentPage?.seo_keywords?.meta_author ?? "",
      og_type: planPageData?.contentPage?.seo_keywords?.og_type ?? "",
      og_title: planPageData?.contentPage?.seo_keywords?.og_title ?? "",
      og_description: planPageData?.contentPage?.seo_keywords?.og_description ?? "",
      og_image_width: planPageData?.contentPage?.seo_keywords?.og_image_width ?? "",
      og_image_height: planPageData?.contentPage?.seo_keywords?.og_image_height ?? "",
      og_url: planPageData?.contentPage?.seo_keywords?.og_url ?? "",
      og_site_name: planPageData?.contentPage?.seo_keywords?.og_site_name ?? "",
      og_locale: planPageData?.contentPage?.seo_keywords?.og_locale ?? "",
      og_locale_alternate: planPageData?.contentPage?.seo_keywords?.og_locale_alternate ?? "",
      twitter_card: planPageData?.contentPage?.seo_keywords?.twitter_card ?? "",
      twitter_site: planPageData?.contentPage?.seo_keywords?.twitter_site ?? "",
      twitter_title: planPageData?.contentPage?.seo_keywords?.twitter_title ?? "",
      twitter_description: planPageData?.contentPage?.seo_keywords?.twitter_description ?? "",
      twitter_url: planPageData?.contentPage?.seo_keywords?.twitter_url ?? "",
    });
    setBannerSectionImageUrl(planPageData?.contentPage?.contentImages?.bannerSectionImage ?? BannerImage);
    setTwitterImageUrl(planPageData?.contentPage?.seo_keywords?.twitter_image ?? BannerImage);
    setOGImageUrl(planPageData?.contentPage?.seo_keywords?.og_image ?? BannerImage);
    setFifthSectionImageUrl(planPageData?.contentPage?.contentImages?.fifthSectionImage ?? BannerImage);
    }
  }, [planPageData]);

  const onUpdate = (values) => {
    const data={
      ...values,
      name:'Plan Page',
      "og_image": ogImageFile ?? "",
      "twitter_image": twitterImageFile ?? "",
      "is_canonical": values.is_canonical === true ? 1 : 0,
      "contentImages[bannerSectionImage]": bannerSectionImageFile ?? "",
      "contentImages[fifthSectionImage]": fifthSectionImageFile ?? "",
      _method: 'PUT'
    }
    dispatch(postPlanPageContentRequest({data,id:planPageData?.contentPage?.id})) 
  };
  return (
    <>
    {planPageStatus === STATUSES.LOADING ? (
      <Loading />
    ) : planPageError === STATUSES.ERROR ? (
      <div>{planPageError}</div>
    ) : (
      <ContentWrapped>
        <div className="row">
          <div className="col-sm-12">
            <Form
              layout="vertical"
              className="pt-3"
              form={form}
              onFinish={onUpdate}
            >
              <div className="row">
                <div className="col-sm-12 d-flex justify-content-between align-items-start mb-4">
                  <h3 className="font-bold" >Plan Page</h3>
                  <SaveButton title="Update" />
                </div>
              </div>
              <div className="row">
              <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                  {/* Banner Section */}
                  {/* <HeadingTitle title="Banner Section" /> */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Text"
                      name="detail[bannerSection][text]"
                      placeText="Banner Section Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Heading"
                      name="detail[bannerSection][heading]"
                      placeText="Banner Section Heading"
                    />
                    <TextAreas
                      labelText="Banner Section Description"
                      name="detail[bannerSection][description]"
                      placeText="Banner Section Description"
                    />
                    <BannerImageCard
                      title="Banner Section Image"
                      imageUrl={bannerSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setBannerSectionImageFile}
                      setImageUrl={setBannerSectionImageUrl}
                      imageText="Change Image"
                    />
                    {/* Plans section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section Heading"
                      name="detail[planSection][heading]"
                      placeText="Plan Section Heading"
                    />
                    <TextAreas
                      labelText="Plan Section Description"
                      name="detail[planSection][description]"
                      placeText="Plan Section Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section First Card Heading"
                      name="detail[planSection][firstCardHeading]"
                      placeText="Plan Section First Card Heading"
                    />
                    <TextAreas
                      labelText="Plan Section First Card Description"
                      name="detail[planSection][firstCardDescription]"
                      placeText="Plan Section First Card Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section First Card Button Text"
                      name="detail[planSection][firstCardButtonText]"
                      placeText="Plan Section First Card Button Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section First Card Benefits Heading"
                      name="detail[planSection][firstCardBenefitsHeading]"
                      placeText="Plan Section First Card Benefits Heading"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section First Card Benefits First Bullet Point"
                      name="detail[planSection][firstCardBenefits][firstBulletPoint]"
                      placeText="Plan Section First Card Benefits First Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section First Card Benefits Second Bullet Point"
                      name="detail[planSection][firstCardBenefits][secondBulletPoint]"
                      placeText="Plan Section First Card Benefits Second Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section First Card Benefits Third Bullet Point"
                      name="detail[planSection][firstCardBenefits][thirdBulletPoint]"
                      placeText="Plan Section First Card Benefits Third Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section First Card Benefits Forth Bullet Point"
                      name="detail[planSection][firstCardBenefits][forthBulletPoint]"
                      placeText="Plan Section First Card Benefits Forth Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section First Card Benefits Fifth Bullet Point"
                      name="detail[planSection][firstCardBenefits][fifthBulletPoint]"
                      placeText="Plan Section First Card Benefits Fifth Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section First Card Payments Heading"
                      name="detail[planSection][firstCardPaymentsHeading]"
                      placeText="Plan Section First Card Payments Heading"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section First Card Payments First Bullet Point"
                      name="detail[planSection][firstCardPayments][firstBulletPoint]"
                      placeText="Plan Section First Card Payments First Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section First Card Payments Second Bullet Point"
                      name="detail[planSection][firstCardPayments][secondBulletPoint]"
                      placeText="Plan Section First Card Payments Second Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section First Card Ownership Heading"
                      name="detail[planSection][firstCardOwnershipHeading]"
                      placeText="Plan Section First Card Ownership Heading"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section First Card Ownership First Bullet Point"
                      name="detail[planSection][firstCardOwnership][firstBulletPoint]"
                      placeText="Plan Section First Card Ownership First Bullet Point"
                    />
                    {/* Second Card */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section Second Card Heading"
                      name="detail[planSection][secondCardHeading]"
                      placeText="Plan Section Second Card Heading"
                    />
                    <TextAreas
                      labelText="Plan Section Second Card Description"
                      name="detail[planSection][secondCardDescription]"
                      placeText="Plan Section Second Card Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section Second Card Button Text"
                      name="detail[planSection][secondCardButtonText]"
                      placeText="Plan Section Second Card Button Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section Second Card Benefits Heading"
                      name="detail[planSection][secondCardBenefitsHeading]"
                      placeText="Plan Section Second Card Benefits Heading"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section Second Card Benefits First Bullet Point"
                      name="detail[planSection][secondCardBenefits][firstBulletPoint]"
                      placeText="Plan Section second Card Benefits First Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section Second Card Benefits Second Bullet Point"
                      name="detail[planSection][secondCardBenefits][secondBulletPoint]"
                      placeText="Plan Section Second Card Benefits Second Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section Second Card Benefits Third Bullet Point"
                      name="detail[planSection][secondCardBenefits][thirdBulletPoint]"
                      placeText="Plan Section Second Card Benefits Third Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section Second Card Benefits Forth Bullet Point"
                      name="detail[planSection][secondCardBenefits][forthBulletPoint]"
                      placeText="Plan Section Second Card Benefits Forth Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section Second Card Benefits Fifth Bullet Point"
                      name="detail[planSection][secondCardBenefits][fifthBulletPoint]"
                      placeText="Plan Section Second Card Benefits Fifth Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section Second Card Payments Heading"
                      name="detail[planSection][secondCardPaymentsHeading]"
                      placeText="Plan Section Second Card Payments Heading"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section Second Card Payments First Bullet Point"
                      name="detail[planSection][secondCardPayments][firstBulletPoint]"
                      placeText="Plan Section Second Card Payments First Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section Second Card Payments Second Bullet Point"
                      name="detail[planSection][secondCardPayments][secondBulletPoint]"
                      placeText="Plan Section Second Card Payments Second Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section Second Card Ownership Heading"
                      name="detail[planSection][secondCardOwnershipHeading]"
                      placeText="Plan Section Second Card Ownership Heading"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section Second Card Ownership First Bullet Point"
                      name="detail[planSection][secondCardOwnership][firstBulletPoint]"
                      placeText="Plan Section Second Card Ownership First Bullet Point"
                    />
                    {/* Third Card */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section Third Card Heading"
                      name="detail[planSection][thirdCardHeading]"
                      placeText="Plan Section Third Card Heading"
                    />
                    <TextAreas
                      labelText="Plan Section Third Card Description"
                      name="detail[planSection][thirdCardDescription]"
                      placeText="Plan Section Third Card Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section Third Card Button Text"
                      name="detail[planSection][thirdCardButtonText]"
                      placeText="Plan Section Third Card Button Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section Third Card Benefits Heading"
                      name="detail[planSection][thirdCardBenefitsHeading]"
                      placeText="Plan Section Third Card Benefits Heading"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section Third Card Benefits First Bullet Point"
                      name="detail[planSection][thirdCardBenefits][firstBulletPoint]"
                      placeText="Plan Section Third Card Benefits First Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section Third Card Benefits Second Bullet Point"
                      name="detail[planSection][thirdCardBenefits][secondBulletPoint]"
                      placeText="Plan Section Third Card Benefits Second Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section Third Card Benefits Third Bullet Point"
                      name="detail[planSection][thirdCardBenefits][thirdBulletPoint]"
                      placeText="Plan Section Third Card Benefits Third Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section Third Card Benefits Forth Bullet Point"
                      name="detail[planSection][thirdCardBenefits][forthBulletPoint]"
                      placeText="Plan Section Third Card Benefits Forth Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section Third Card Benefits Fifth Bullet Point"
                      name="detail[planSection][thirdCardBenefits][fifthBulletPoint]"
                      placeText="Plan Section Third Card Benefits Fifth Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section Third Card Payments Heading"
                      name="detail[planSection][thirdCardPaymentsHeading]"
                      placeText="Plan Section Third Card Payments Heading"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section Third Card Payments First Bullet Point"
                      name="detail[planSection][thirdCardPayments][firstBulletPoint]"
                      placeText="Plan Section Third Card Payments First Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section Third Card Payments Second Bullet Point"
                      name="detail[planSection][thirdCardPayments][secondBulletPoint]"
                      placeText="Plan Section Third Card Payments Second Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section Third Card Ownership Heading"
                      name="detail[planSection][thirdCardOwnershipHeading]"
                      placeText="Plan Section Third Card Ownership Heading"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Plan Section Third Card Ownership First Bullet Point"
                      name="detail[planSection][thirdCardOwnership][firstBulletPoint]"
                      placeText="Plan Section Third Card Ownership First Bullet Point"
                    />
                    {/* Pros & Cons Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Text"
                      name="detail[inquiringSection][text]"
                      placeText="Inquiring Section Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Heading"
                      name="detail[inquiringSection][heading]"
                      placeText="Inquiring Section Heading"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Pros Heading"
                      name="detail[inquiringSection][prosHeading]"
                      placeText="Inquiring Section Pros Heading"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Cons Heading"
                      name="detail[inquiringSection][consHeading]"
                      placeText="Inquiring Section Cons Heading"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Lease Heading"
                      name="detail[inquiringSection][leaseHeading]"
                      placeText="Inquiring Section Lease Heading"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Lease Pros First Bullet Point"
                      name="detail[inquiringSection][leasePros][firstBulletPoint]"
                      placeText="Inquiring Section Lease Pros First Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Lease Pros Second Bullet Point"
                      name="detail[inquiringSection][leasePros][secondBulletPoint]"
                      placeText="Inquiring Section Lease Pros Second Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Lease Pros Third Bullet Point"
                      name="detail[inquiringSection][leasePros][thirdBulletPoint]"
                      placeText="Inquiring Section Lease Pros Third Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Lease Pros Forth Bullet Point"
                      name="detail[inquiringSection][leasePros][forthBulletPoint]"
                      placeText="Inquiring Section Lease Pros Forth Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Lease Pros Fifth Bullet Point"
                      name="detail[inquiringSection][leasePros][fifthBulletPoint]"
                      placeText="Inquiring Section Lease Pros Fifth Bullet Point"
                    />
                    {/* Lease Cons */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Lease Cons First Bullet Point"
                      name="detail[inquiringSection][leaseCons][firstBulletPoint]"
                      placeText="Inquiring Section Lease Cons First Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Lease Cons Second Bullet Point"
                      name="detail[inquiringSection][leaseCons][secondBulletPoint]"
                      placeText="Inquiring Section Lease Cons Second Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Lease Cons Third Bullet Point"
                      name="detail[inquiringSection][leaseCons][thirdBulletPoint]"
                      placeText="Inquiring Section Lease Cons Third Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Lease Cons Forth Bullet Point"
                      name="detail[inquiringSection][leaseCons][forthBulletPoint]"
                      placeText="Inquiring Section Lease Cons Forth Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Lease Cons Fifth Bullet Point"
                      name="detail[inquiringSection][leaseCons][fifthBulletPoint]"
                      placeText="Inquiring Section Lease Cons Fifth Bullet Point"
                    />
                    {/* Loan Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Loan Heading"
                      name="detail[inquiringSection][loanHeading]"
                      placeText="Inquiring Section Loan Heading"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Loan Pros First Bullet Point"
                      name="detail[inquiringSection][loanPros][firstBulletPoint]"
                      placeText="Inquiring Section Loan Pros First Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Loan Pros Second Bullet Point"
                      name="detail[inquiringSection][loanPros][secondBulletPoint]"
                      placeText="Inquiring Section Loan Pros Second Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Loan Pros Third Bullet Point"
                      name="detail[inquiringSection][loanPros][thirdBulletPoint]"
                      placeText="Inquiring Section Loan Pros Third Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Loan Pros Forth Bullet Point"
                      name="detail[inquiringSection][loanPros][forthBulletPoint]"
                      placeText="Inquiring Section Loan Pros Forth Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Loan Pros Fifth Bullet Point"
                      name="detail[inquiringSection][loanPros][fifthBulletPoint]"
                      placeText="Inquiring Section Loan Pros Fifth Bullet Point"
                    />
                    {/* Loan Cons */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Loan Cons First Bullet Point"
                      name="detail[inquiringSection][loanCons][firstBulletPoint]"
                      placeText="Inquiring Section Loan Cons First Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Loan Cons Second Bullet Point"
                      name="detail[inquiringSection][loanCons][secondBulletPoint]"
                      placeText="Inquiring Section Loan Cons Second Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Loan Cons Third Bullet Point"
                      name="detail[inquiringSection][loanCons][thirdBulletPoint]"
                      placeText="Inquiring Section Loan Cons Third Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Loan Cons Forth Bullet Point"
                      name="detail[inquiringSection][loanCons][forthBulletPoint]"
                      placeText="Inquiring Section Loan Cons Forth Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Loan Cons Fifth Bullet Point"
                      name="detail[inquiringSection][loanCons][fifthBulletPoint]"
                      placeText="Inquiring Section Loan Cons Fifth Bullet Point"
                    />
                    {/* Purchase Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Purchase Heading"
                      name="detail[inquiringSection][purchaseHeading]"
                      placeText="Inquiring Section Purchase Heading"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Purchase Pros First Bullet Point"
                      name="detail[inquiringSection][purchasePros][firstBulletPoint]"
                      placeText="Inquiring Section Purchase Pros First Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Purchase Pros Second Bullet Point"
                      name="detail[inquiringSection][purchasePros][secondBulletPoint]"
                      placeText="Inquiring Section Purchase Pros Second Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Purchase Pros Third Bullet Point"
                      name="detail[inquiringSection][purchasePros][thirdBulletPoint]"
                      placeText="Inquiring Section Purchase Pros Third Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Purchase Pros Forth Bullet Point"
                      name="detail[inquiringSection][purchasePros][forthBulletPoint]"
                      placeText="Inquiring Section Purchase Pros Forth Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Purchase Pros Fifth Bullet Point"
                      name="detail[inquiringSection][purchasePros][fifthBulletPoint]"
                      placeText="Inquiring Section Purchase Pros Fifth Bullet Point"
                    />
                    {/* Purchase Cons */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Purchase Cons First Bullet Point"
                      name="detail[inquiringSection][purchaseCons][firstBulletPoint]"
                      placeText="Inquiring Section Purchase Cons First Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Purchase Cons Second Bullet Point"
                      name="detail[inquiringSection][purchaseCons][secondBulletPoint]"
                      placeText="Inquiring Section Purchase Cons Second Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Purchase Cons Third Bullet Point"
                      name="detail[inquiringSection][purchaseCons][thirdBulletPoint]"
                      placeText="Inquiring Section Purchase Cons Third Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Purchase Cons Forth Bullet Point"
                      name="detail[inquiringSection][purchaseCons][forthBulletPoint]"
                      placeText="Inquiring Section Purchase Cons Forth Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inquiring Section Purchase Cons Fifth Bullet Point"
                      name="detail[inquiringSection][purchaseCons][fifthBulletPoint]"
                      placeText="Inquiring Section Purchase Cons Fifth Bullet Point"
                    />
                    {/* Testimonial section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Testimonials Section Text"
                      name="detail[testimonialsSection][text]"
                      placeText="Testimonials Section Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Testimonials Section Heading"
                      name="detail[testimonialsSection][heading]"
                      placeText="Testimonials Section Heading"
                    />
                    {/* Fifth Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Fifth Section Heading"
                      name="detail[fifthSection][heading]"
                      placeText="Fifth Section Heading"
                    />
                    <TextAreas
                      labelText="Fifth Section Description"
                      name="detail[fifthSection][description]"
                      placeText="Fifth Section Description"
                    />
                    <BannerImageCard
                      title="Fifth Section Image"
                      imageUrl={fifthSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setFifthSectionImageFile}
                      setImageUrl={setFifthSectionImageUrl}
                      imageText="Change Image"
                    />
                </div>
                <SeoSection ogImageUrl={ogImageUrl}
                  setOGImageFile={setOGImageFile}
                  setOGImageUrl={setOGImageUrl}
                  twitterImageUrl={twitterImageUrl}
                  setTwitterImageFile={setTwitterImageFile}
                  setTwitterImageUrl={setTwitterImageUrl} />
              </div>

            </Form>
          </div>
        </div>
      </ContentWrapped>
    )}
  </>
);
};

export default PlanPage;
