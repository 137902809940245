import React from "react";
import { SideBarTabWrapped } from "./style";

const SideBarTab = () => {
  return (
    <>
      <SideBarTabWrapped>
        <div className="row">
          <div className="col-lg-3 col-12">
            <div className="side-tabs d-lg-flex flex-column">
              <div
                className="nav nav_side_tab nav-pills"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                <button
                  className="nav-link active col"
                  id="v-pills-home-tab"
                  aria-controls="v-pills-home"
                  data-bs-target="#v-pills-home"
                  data-bs-toggle="pill"
                  type="button"
                  role="tab"
                  aria-selected="true"
                >
                  <span>Home</span>
                </button>
                <button
                  className="nav-link mr-3"
                  id="v-pills-commercial-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-commercial"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-commercial"
                  aria-selected="false"
                >
                  <span>Commercial Solar</span>
                </button>
                <button
                  className="nav-link mr-3"
                  id="v-pills-residential-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-residential"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-residential"
                  aria-selected="false"
                >
                  <span>Residential Solar</span>
                </button>
                <button
                  className="nav-link mr-3"
                  id="v-pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-profile"
                  aria-selected="false"
                >
                  <span>Gallery</span>
                </button>
                <button
                  className="nav-link mr-3"
                  id="v-pills-solar-panels-page-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-solar-panels-page"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-solar-panels-page"
                  aria-selected="false"
                >
                  <span>Solar Panels Page</span>
                </button>
                <button
                  className="nav-link mr-3"
                  id="v-pills-battery-storage-page-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-battery-storage-page"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-battery-storage-page"
                  aria-selected="false"
                >
                  <span>Battery Storage Page</span>
                </button>
                <button
                  className="nav-link mr-3"
                  id="v-pills-tesla-power-wall-page-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-tesla-power-wall-page"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-tesla-power-wall-page"
                  aria-selected="false"
                >
                  <span>Tesla Power Wall Page</span>
                </button>
                <button
                  className="nav-link mr-3"
                  id="v-pills-shift-page-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-shift-page"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-shift-page"
                  aria-selected="false"
                >
                  <span>Shift Page</span>
                </button>
                <button
                  className="nav-link mr-3"
                  id="v-pills-lightning-page-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-lightning-page"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-lightning-page"
                  aria-selected="false"
                >
                  <span>Lightning Page</span>
                </button>
                <button
                  className="nav-link mr-3"
                  id="v-pills-get-quote-page-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-get-quote-page"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-get-quote-page"
                  aria-selected="false"
                >
                  <span>Get a Quote Page</span>
                </button>
                <button
                  className="nav-link mr-3"
                  id="v-pills-plans-page-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-plans-page"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-plans-page"
                  aria-selected="false"
                >
                  <span>Plans Page</span>
                </button>
                <button
                  className="nav-link mr-3"
                  id="v-pills-charging-page-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-charging-page"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-charging-page"
                  aria-selected="false"
                >
                  <span>Charging Page</span>
                </button>
                <button
                  className="nav-link mr-3"
                  id="v-pills-about-us-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-about-us"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-about-us"
                  aria-selected="false"
                >
                  <span>About Us</span>
                </button>
                {/* <button
              className="nav-link"
              id="v-pills-messages-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-messages"
              type="button"
              role="tab"
              aria-controls="v-pills-messages"
              aria-selected="false"
            >
              <span>About Us</span>
            </button> */}
                <button
                  className="nav-link col"
                  id="terms-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#terms"
                  type="button"
                  role="tab"
                  aria-controls="terms"
                  aria-selected="false"
                >
                  <span>Terms & Conditions</span>
                </button>
                <button
                  className="nav-link"
                  id="privacy-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#privacy"
                  type="button"
                  role="tab"
                  aria-controls="privacy"
                  aria-selected="false"
                >
                  <span>Privacy Policy</span>
                </button>
                <button
                  className="nav-link"
                  id="cookie-policy-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#cookie-policy"
                  type="button"
                  role="tab"
                  aria-controls="cookie-policy"
                  aria-selected="false"
                >
                  <span>Cookie Policy</span>
                </button>
                <button
                  className="nav-link"
                  id="footer-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#footer"
                  type="button"
                  role="tab"
                  aria-controls="footer"
                  aria-selected="false"
                >
                  <span>Web Site Footer</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </SideBarTabWrapped>
    </>
  );
};
export default SideBarTab;
