import { Form } from "antd";
import React, { useEffect, useState } from "react";
import BaseInput from "../../components/form/BaseInput";
import { ContentWrapped } from "./style";
import SaveButton from "../../components/button/saveButton";
import { useDispatch, useSelector } from "react-redux";
import {
  getAboutPageContentRequest,
  postAboutPageContentRequest,
} from "../../redux/contentManagementSlice";
import Loading from "../../components/shared/GeneralComponents";
import {
  BannerImage,
  CONTENT_PAGES,
  STATUSES,
} from "../../config/constants/defaultValues";
import TextAreas from "../../components/form/TextAreas";
import SeoSection from "../../components/shared/seoSection";
import BannerImageCard from "../../components/shared/bannerImageCard";
import VideoCard from "../../components/shared/VideoCard";

const AboutPage = () => {
  const dispatch = useDispatch();
  const { aboutPageData, aboutPageStatus, aboutPageError } = useSelector(
    (state) => state.contentManagement
  );
  const [form] = Form.useForm();

  // Banner Section
  const [bannerSectionImageUrl, setBannerSectionImageUrl] = useState();
  const [bannerSectionImageFile, setBannerSectionImageFile] = useState();
  // Story Section
  const [storySectionImageUrl, setStorySectionImageUrl] = useState();
  const [storySectionImageFile, setStorySectionImageFile] = useState();
  // Mission Section
  const [missionSectionImageUrl, setMissionSectionImageUrl] = useState();
  const [missionSectionImageFile, setMissionSectionImageFile] = useState();
  // Video Section
  const [videoSectionImageUrl, setVideoSectionImageUrl] = useState();
  const [videoSectionImageFile, setVideoSectionImageFile] = useState();
  const [videoSectionUrl, setVideoSectionUrl] = useState();
  const [videoSectionFile, setVideoSectionFile] = useState();
  //Seo Section states
  const [ogImageUrl, setOGImageUrl] = useState();
  const [ogImageFile, setOGImageFile] = useState();
  const [twitterImageUrl, setTwitterImageUrl] = useState();
  const [twitterImageFile, setTwitterImageFile] = useState();

  useEffect(() => {
    dispatch(getAboutPageContentRequest(CONTENT_PAGES.ABOUT_US_PAGE));
  }, [dispatch]);

  useEffect(() => {
    if (aboutPageData) {
      form.setFieldsValue({
        "detail[bannerSection][text]": aboutPageData?.contentPage?.detail?.bannerSection?.text,
        "detail[bannerSection][heading]": aboutPageData?.contentPage?.detail?.bannerSection?.heading,
        // Story Section
        "detail[storySection][text]": aboutPageData?.contentPage?.detail?.storySection?.text,
        "detail[storySection][heading]": aboutPageData?.contentPage?.detail?.storySection?.heading,
        "detail[storySection][description]": aboutPageData?.contentPage?.detail?.storySection?.description,
        // Commitment Section
        "detail[commitmentSection][text]": aboutPageData?.contentPage?.detail?.commitmentSection?.text,
        "detail[commitmentSection][heading]": aboutPageData?.contentPage?.detail?.commitmentSection?.heading,
        "detail[commitmentSection][description]": aboutPageData?.contentPage?.detail?.commitmentSection?.description,
        "detail[commitmentSection][firstSubHeading]": aboutPageData?.contentPage?.detail?.commitmentSection?.firstSubHeading,
        "detail[commitmentSection][firstSubDescription]": aboutPageData?.contentPage?.detail?.commitmentSection?.firstSubDescription,
        "detail[commitmentSection][secondSubHeading]": aboutPageData?.contentPage?.detail?.commitmentSection?.secondSubHeading,
        "detail[commitmentSection][secondSubDescription]": aboutPageData?.contentPage?.detail?.commitmentSection?.secondSubDescription,
        "detail[commitmentSection][thirdSubHeading]": aboutPageData?.contentPage?.detail?.commitmentSection?.thirdSubHeading,
        "detail[commitmentSection][thirdSubDescription]": aboutPageData?.contentPage?.detail?.commitmentSection?.thirdSubDescription,
        "detail[commitmentSection][buttonText]": aboutPageData?.contentPage?.detail?.commitmentSection?.buttonText,
        // Stats Section
        "detail[statsSection][firstCounterValue]": aboutPageData?.contentPage?.detail?.statsSection?.firstCounterValue,
        "detail[statsSection][firstCounterText]": aboutPageData?.contentPage?.detail?.statsSection?.firstCounterText,
        "detail[statsSection][secondCounterValue]": aboutPageData?.contentPage?.detail?.statsSection?.secondCounterValue,
        "detail[statsSection][secondCounterText]": aboutPageData?.contentPage?.detail?.statsSection?.secondCounterText,
        "detail[statsSection][thirdCounterValue]": aboutPageData?.contentPage?.detail?.statsSection?.thirdCounterValue,
        "detail[statsSection][thirdCounterText]": aboutPageData?.contentPage?.detail?.statsSection?.thirdCounterText,
        "detail[statsSection][forthCounterValue]": aboutPageData?.contentPage?.detail?.statsSection?.forthCounterValue,
        "detail[statsSection][forthCounterText]": aboutPageData?.contentPage?.detail?.statsSection?.forthCounterText,
        // Mission Section
        "detail[missionSection][text]": aboutPageData?.contentPage?.detail?.missionSection?.text,
        "detail[missionSection][heading]": aboutPageData?.contentPage?.detail?.missionSection?.heading,
        // Video Section
        "detail[videoSection][heading]": aboutPageData?.contentPage?.detail?.videoSection?.heading,
        "detail[videoSection][description]": aboutPageData?.contentPage?.detail?.videoSection?.description,
        "detail[videoSection][buttonText]": aboutPageData?.contentPage?.detail?.videoSection?.buttonText,
        // Get Quote Section
        "detail[getQuoteSection][text]": aboutPageData?.contentPage?.detail?.getQuoteSection?.text,
        "detail[getQuoteSection][heading]": aboutPageData?.contentPage?.detail?.getQuoteSection?.heading,
        "detail[getQuoteSection][formHeading]": aboutPageData?.contentPage?.detail?.getQuoteSection?.formHeading,
        "detail[getQuoteSection][formConfirmationText]": aboutPageData?.contentPage?.detail?.getQuoteSection?.formConfirmationText,
        "detail[getQuoteSection][buttonText]": aboutPageData?.contentPage?.detail?.getQuoteSection?.buttonText,

        //Seo Values Set
        meta_title: aboutPageData?.contentPage?.seo_keywords?.meta_title ?? "",
        meta_description: aboutPageData?.contentPage?.seo_keywords?.meta_description ?? "",
        is_canonical: aboutPageData?.contentPage?.seo_keywords?.is_canonical === 1 ? true : false,
        robots_meta: aboutPageData?.contentPage?.seo_keywords?.robots_meta ?? "",
        meta_author: aboutPageData?.contentPage?.seo_keywords?.meta_author ?? "",
        og_type: aboutPageData?.contentPage?.seo_keywords?.og_type ?? "",
        og_title: aboutPageData?.contentPage?.seo_keywords?.og_title ?? "",
        og_description: aboutPageData?.contentPage?.seo_keywords?.og_description ?? "",
        og_image_width: aboutPageData?.contentPage?.seo_keywords?.og_image_width ?? "",
        og_image_height: aboutPageData?.contentPage?.seo_keywords?.og_image_height ?? "",
        og_url: aboutPageData?.contentPage?.seo_keywords?.og_url ?? "",
        og_site_name: aboutPageData?.contentPage?.seo_keywords?.og_site_name ?? "",
        og_locale: aboutPageData?.contentPage?.seo_keywords?.og_locale ?? "",
        og_locale_alternate: aboutPageData?.contentPage?.seo_keywords?.og_locale_alternate ?? "",
        twitter_card: aboutPageData?.contentPage?.seo_keywords?.twitter_card ?? "",
        twitter_site: aboutPageData?.contentPage?.seo_keywords?.twitter_site ?? "",
        twitter_title: aboutPageData?.contentPage?.seo_keywords?.twitter_title ?? "",
        twitter_description: aboutPageData?.contentPage?.seo_keywords?.twitter_description ?? "",
        twitter_url: aboutPageData?.contentPage?.seo_keywords?.twitter_url ?? "",
      });
      setBannerSectionImageUrl(aboutPageData?.contentPage?.contentImages?.bannerSectionImage ?? BannerImage);
      setStorySectionImageUrl(aboutPageData?.contentPage?.contentImages?.storySectionImage ?? BannerImage);
      setMissionSectionImageUrl(aboutPageData?.contentPage?.contentImages?.missionSectionImage ?? BannerImage);
      setVideoSectionImageUrl(aboutPageData?.contentPage?.contentImages?.missionVideoThumbnail ?? null);
      setVideoSectionUrl(aboutPageData?.contentPage?.contentImages?.missionVideo ?? null);
      setTwitterImageUrl(aboutPageData?.contentPage?.seo_keywords?.twitter_image ?? BannerImage);
      setOGImageUrl(aboutPageData?.contentPage?.seo_keywords?.og_image ?? BannerImage);
    }
  }, [aboutPageData]);

  const onUpdate = (values) => {
    const data = {
      ...values,
      name: "About Us",
      "contentImages[bannerSectionImage]": bannerSectionImageFile ?? "",
      "contentImages[storySectionImage]": storySectionImageFile ?? "",
      "contentImages[missionSectionImage]": missionSectionImageFile ?? "",
      "contentImages[missionVideoThumbnail]": videoSectionImageFile ?? "",
      "contentImages[missionVideo]": videoSectionFile ?? "",
      og_image: ogImageFile ?? "",
      twitter_image: twitterImageFile ?? "",
      is_canonical: values.is_canonical === true ? 1 : 0,
      _method: "PUT",
    };
    dispatch(
      postAboutPageContentRequest({ data, id: aboutPageData?.contentPage?.id })
    );
  };
  return (
    <>
      {aboutPageStatus === STATUSES.LOADING ? (
        <Loading />
      ) : aboutPageError === STATUSES.ERROR ? (
        <div>{aboutPageError}</div>
      ) : (
        <ContentWrapped>
          <div className="row">
            <div className="col-sm-12">
              <Form
                layout="vertical"
                className="pt-3"
                form={form}
                onFinish={onUpdate}
              >
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-between align-items-start mb-4">
                    <h3 className="font-bold">About Page</h3>
                    <SaveButton title="Update" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                    {/* Banner Section */}
                    {/* <HeadingTitle title="Banner Section" /> */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Text"
                      name="detail[bannerSection][text]"
                      placeText="Banner Section Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Heading"
                      name="detail[bannerSection][heading]"
                      placeText="Banner Section Heading"
                    />
                    <BannerImageCard
                      title="Banner Section Image"
                      imageUrl={bannerSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setBannerSectionImageFile}
                      setImageUrl={setBannerSectionImageUrl}
                      imageText="Change Image"
                    />
                    {/* Story Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Story Section Text"
                      name="detail[storySection][text]"
                      placeText="Story Section Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Story Section Heading"
                      name="detail[storySection][heading]"
                      placeText="Story Section Heading"
                    />
                    <TextAreas
                      labelText="Story Section Description"
                      name="detail[storySection][description]"
                      placeText="Story Section Description"
                    />
                    <BannerImageCard
                      title="Story Section Image"
                      imageUrl={storySectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setStorySectionImageFile}
                      setImageUrl={setStorySectionImageUrl}
                      imageText="Change Image"
                    />
                    {/* Commitment Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Commitment Section Text"
                      name="detail[commitmentSection][text]"
                      placeText="Commitment Section Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Commitment Section Heading"
                      name="detail[commitmentSection][heading]"
                      placeText="Commitment Section Heading"
                    />
                    <TextAreas
                      labelText="Commitment Section Description"
                      name="detail[commitmentSection][description]"
                      placeText="Commitment Section Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Commitment Section First Sub-Heading"
                      name="detail[commitmentSection][firstSubHeading]"
                      placeText="Commitment Section First Sub-Heading"
                    />
                    <TextAreas
                      labelText="Commitment Section First Sub-Description"
                      name="detail[commitmentSection][firstSubDescription]"
                      placeText="Commitment Section First Sub-Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Commitment Section Second Sub-Heading"
                      name="detail[commitmentSection][secondSubHeading]"
                      placeText="Commitment Section Second Sub-Heading"
                    />
                    <TextAreas
                      labelText="Commitment Section Second Sub-Description"
                      name="detail[commitmentSection][secondSubDescription]"
                      placeText="Commitment Section Second Sub-Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Commitment Section Third Sub-Heading"
                      name="detail[commitmentSection][thirdSubHeading]"
                      placeText="Commitment Section Third Sub-Heading"
                    />
                    <TextAreas
                      labelText="Commitment Section Third Sub-Description"
                      name="detail[commitmentSection][thirdSubDescription]"
                      placeText="Commitment Section Third Sub-Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Commitment Section Button Text"
                      name="detail[commitmentSection][buttonText]"
                      placeText="Commitment Section Button Text"
                    />
                    {/* Stats Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Stats Section First Counter Value"
                      name="detail[statsSection][firstCounterValue]"
                      placeText="Stats Section First Counter Value"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Stats Section First Counter Text"
                      name="detail[statsSection][firstCounterText]"
                      placeText="Stats Section First Counter Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Stats Section Second Counter Value"
                      name="detail[statsSection][secondCounterValue]"
                      placeText="Stats Section Second Counter Value"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Stats Section Second Counter Text"
                      name="detail[statsSection][secondCounterText]"
                      placeText="Stats Section Second Counter Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Stats Section Third Counter Value"
                      name="detail[statsSection][thirdCounterValue]"
                      placeText="Stats Section Third Counter Value"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Stats Section Third Counter Text"
                      name="detail[statsSection][thirdCounterText]"
                      placeText="Stats Section Third Counter Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Stats Section Forth Counter Value"
                      name="detail[statsSection][forthCounterValue]"
                      placeText="Stats Section Forth Counter Value"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Stats Section Forth Counter Text"
                      name="detail[statsSection][forthCounterText]"
                      placeText="Stats Section Forth Counter Text"
                    />
                    {/* Mission Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Mission Section Text"
                      name="detail[missionSection][text]"
                      placeText="Mission Section Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Mission Section Heading"
                      name="detail[missionSection][heading]"
                      placeText="Mission Section Heading"
                    />
                    <BannerImageCard
                      title="Mission Section Image"
                      imageUrl={missionSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setMissionSectionImageFile}
                      setImageUrl={setMissionSectionImageUrl}
                      imageText="Change Image"
                    />
                    {/* Video Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Video Section Heading"
                      name="detail[videoSection][heading]"
                      placeText="Video Section Heading"
                    />
                    <TextAreas
                      labelText="Video Section Description"
                      name="detail[videoSection][description]"
                      placeText="Video Section Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Video Section Button Text"
                      name="detail[videoSection][buttonText]"
                      placeText="Video Section Button Text"
                    />
                    <label>Video Section *</label>
                    <VideoCard
                      width={400} height={300}
                      videoUrl={videoSectionUrl}
                      setVideoFile={setVideoSectionFile}
                      setVideoUrl={setVideoSectionUrl}
                    />
                    <br/>
                    <BannerImageCard
                      title="Video Section Thumbnail Image"
                      imageUrl={videoSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setVideoSectionImageFile}
                      setImageUrl={setVideoSectionImageUrl}
                      imageText="Change Image"
                    />
                    {/* Get Quote section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Get Quote Section Text"
                      name="detail[getQuoteSection][text]"
                      placeText="Get Quote Section Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Get Quote Section Heading"
                      name="detail[getQuoteSection][heading]"
                      placeText="Get Quote Section Heading"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Get Quote Section Form Heading"
                      name="detail[getQuoteSection][formHeading]"
                      placeText="Get Quote Section Form Heading"
                    />
                    <TextAreas
                      labelText="Get Quote Section Form Confirmation Text"
                      name="detail[getQuoteSection][formConfirmationText]"
                      placeText="Get Quote Section Form Confirmation Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Get Quote Section Button Text"
                      name="detail[getQuoteSection][buttonText]"
                      placeText="Get Quote Section Button Text"
                    />
                  </div>
                  <SeoSection
                    ogImageUrl={ogImageUrl}
                    setOGImageFile={setOGImageFile}
                    setOGImageUrl={setOGImageUrl}
                    twitterImageUrl={twitterImageUrl}
                    setTwitterImageFile={setTwitterImageFile}
                    setTwitterImageUrl={setTwitterImageUrl}
                  />
                </div>
              </Form>
            </div>
          </div>
        </ContentWrapped>
      )}
    </>
  );
};

export default AboutPage;
