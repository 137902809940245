import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import dashboardReducer from "./dashboardSlice";
import contentManagementReducer from "./contentManagementSlice";
import estimateFormListReducer from "./estimateFormListSlice";
import contactFormListReducer from "./contactFormListSlice";
import systemConfigurationReducer from "./systemConfigurationSlice";
import userListReducer from "./userSlice";
export default configureStore({
  reducer: {
    auth: authReducer,
    dashboard: dashboardReducer,
    contentManagement: contentManagementReducer,
    systemConfiguration: systemConfigurationReducer,
    contactFormList: contactFormListReducer,
    estimateFormList: estimateFormListReducer,
    userList: userListReducer,
  },
});
