import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { errorNotification, successNotification } from "../components/notifications/alertNotification";
import { REQUEST_METHOD, STATUSES } from "../config/constants/defaultValues";
import { GET_SYSTEM_CONFIGURATION_DETAIL_API, GET_SYSTEM_CONFIGURATION_LIST_API, POST_SYSTEM_CONFIGURATION_DETAIL_API, POST_TESTIMONIALS_LIST_API, GET_TESTIMONIALS_LIST_API, POST_SERVICES_LIST_API, GET_SERVICES_LIST_API, POST_PROJECTS_LIST_API, GET_PROJECTS_LIST_API } from "../config/constants/endPoints";
import { apiService } from "../config/services/api.service";


export const getSystemConfigurationListRequest = createAsyncThunk(
    "systemConfiguration/getSystemConfigurationListRequest",
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiService(
                GET_SYSTEM_CONFIGURATION_LIST_API,
                REQUEST_METHOD.GET,
                {},
                true
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const getSystemConfigurationDetailRequest = createAsyncThunk(
    "systemConfiguration/getSystemConfigurationDetailRequest",
    async ({ modelData }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                GET_SYSTEM_CONFIGURATION_DETAIL_API,
                REQUEST_METHOD.POST,
                {
                    model_path: modelData.model_path,
                    model_name: modelData.model_name,
                    status: 1,
                },
                true
            );
            if (response.status === 200) {

                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);

export const systemConfigurationPostRequest = createAsyncThunk(
    "systemConfiguration/systemConfigurationPostRequest",
    async (values, { rejectWithValue }) => {
        try {
            const response = await apiService(
                POST_SYSTEM_CONFIGURATION_DETAIL_API,
                REQUEST_METHOD.POST,
                values,
                true
            );

            if (response.status === 200) {
                successNotification(response.data.message);
                return true;
            }
            else {
                errorNotification(Object.values(response.data.errors));
                return false;
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return false;
        }
    }
);

export const getConfigurationalTestimonialListRequest = createAsyncThunk(
    "systemConfiguration/getConfigurationalTestimonialListRequest",
    async ({ generalSearch, page, pageSize, dateRange }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                GET_TESTIMONIALS_LIST_API,
                REQUEST_METHOD.POST,
                {
                    "pagination": { "page": page ?? 1, "per_page": pageSize ?? 30, "export": "0" },
                    "query": { "status": "1", "generalSearch": generalSearch ?? "", "dateRange": dateRange ?? "" }
                },
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const getConfigurationalTestimonialListExportDataRequest = createAsyncThunk(
    "systemConfiguration/getConfigurationalTestimonialListExportDataRequest",
    async ({ generalSearch, dateRange }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                GET_TESTIMONIALS_LIST_API,
                REQUEST_METHOD.POST,
                {
                    "pagination": { "page": 1, "per_page": 30, "export": "1" },
                    "query": { "status": "1", "generalSearch": generalSearch, "dateRange": dateRange ?? "" }
                },
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const createConfigurationalTestimonialRequest = createAsyncThunk(
    "systemConfiguration/createConfigurationalTestimonialRequest",
    async ({ values }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });

    // async ({ values }, { rejectWithValue }) => {
        try {
            // const newData = new FormData();
            const response = await apiService(
                POST_TESTIMONIALS_LIST_API,
                REQUEST_METHOD.POST,
                newData,
                true
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response;
            }
            else {
                errorNotification(Object.values(response.data.errors));
                return response;
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const editConfigurationalTestimonialRequest = createAsyncThunk(
    "systemConfiguration/editConfigurationalTestimonialRequest",
    async ({ values }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });

    // async ({ values }, { rejectWithValue }) => {
        try {
            // const newData = new FormData();
            const response = await apiService(
                POST_TESTIMONIALS_LIST_API + "/" + values.testimonial_id,
                REQUEST_METHOD.PUT,
                values,
                true
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response;
            }
            else {
                errorNotification(Object.values(response.data.errors));
                return response;
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const deleteConfigurationalTestimonialRequest = createAsyncThunk(
    "systemConfiguration/deleteConfigurationalTestimonialRequest",
    async (id, { rejectWithValue }) => {
        try {
            // const newData = new FormData();
            const response = await apiService(
                POST_TESTIMONIALS_LIST_API + "/" + id,
                REQUEST_METHOD.DELETE,
                null,
                true
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response;
            }
            else {
                errorNotification(Object.values(response.data.errors));
                return response;
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// Service
export const getConfigurationalServiceListRequest = createAsyncThunk(
    "systemConfiguration/getConfigurationalServiceListRequest",
    async ({ generalSearch, page, pageSize, dateRange }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                GET_SERVICES_LIST_API,
                REQUEST_METHOD.POST,
                {
                    "pagination": { "page": page ?? 1, "per_page": pageSize ?? 30, "export": "0" },
                    "query": { "status": "1", "generalSearch": generalSearch ?? "", "dateRange": dateRange ?? "" }
                },
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const getConfigurationalServiceListExportDataRequest = createAsyncThunk(
    "systemConfiguration/getConfigurationalServiceListExportDataRequest",
    async ({ generalSearch, dateRange }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                GET_SERVICES_LIST_API,
                REQUEST_METHOD.POST,
                {
                    "pagination": { "page": 1, "per_page": 30, "export": "1" },
                    "query": { "status": "1", "generalSearch": generalSearch, "dateRange": dateRange ?? "" }
                },
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const createConfigurationalServiceRequest = createAsyncThunk(
    "systemConfiguration/createConfigurationalServiceRequest",
    async ({ values }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });

    // async ({ values }, { rejectWithValue }) => {
        try {
            // const newData = new FormData();
            const response = await apiService(
                POST_SERVICES_LIST_API,
                REQUEST_METHOD.POST,
                newData,
                true
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response;
            }
            else {
                errorNotification(Object.values(response.data.errors));
                return response;
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const editConfigurationalServiceRequest = createAsyncThunk(
    "systemConfiguration/editConfigurationalServiceRequest",
    async ({ values }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });

    // async ({ values }, { rejectWithValue }) => {
        try {
            // const newData = new FormData();
            const response = await apiService(
                POST_SERVICES_LIST_API + "/" + values.service_id,
                REQUEST_METHOD.PUT,
                values,
                true
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response;
            }
            else {
                errorNotification(Object.values(response.data.errors));
                return response;
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const deleteConfigurationalServiceRequest = createAsyncThunk(
    "systemConfiguration/deleteConfigurationalServiceRequest",
    async (id, { rejectWithValue }) => {
        try {
            // const newData = new FormData();
            const response = await apiService(
                POST_SERVICES_LIST_API + "/" + id,
                REQUEST_METHOD.DELETE,
                null,
                true
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response;
            }
            else {
                errorNotification(Object.values(response.data.errors));
                return response;
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// Project
export const getConfigurationalProjectListRequest = createAsyncThunk(
    "systemConfiguration/getConfigurationalProjectListRequest",
    async ({ generalSearch, page, pageSize, dateRange }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                GET_PROJECTS_LIST_API,
                REQUEST_METHOD.POST,
                {
                    "pagination": { "page": page ?? 1, "per_page": pageSize ?? 30, "export": "0" },
                    "query": { "status": "1", "generalSearch": generalSearch ?? "", "dateRange": dateRange ?? "" }
                },
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const getConfigurationalProjectListExportDataRequest = createAsyncThunk(
    "systemConfiguration/getConfigurationalProjectListExportDataRequest",
    async ({ generalSearch, dateRange }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                GET_PROJECTS_LIST_API,
                REQUEST_METHOD.POST,
                {
                    "pagination": { "page": 1, "per_page": 30, "export": "1" },
                    "query": { "status": "1", "generalSearch": generalSearch, "dateRange": dateRange ?? "" }
                },
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const createConfigurationalProjectRequest = createAsyncThunk(
    "systemConfiguration/createConfigurationalProjectRequest",
    async ({ values }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });

    // async ({ values }, { rejectWithValue }) => {
        try {
            // const newData = new FormData();
            const response = await apiService(
                POST_PROJECTS_LIST_API,
                REQUEST_METHOD.POST,
                newData,
                true
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response;
            }
            else {
                errorNotification(Object.values(response.data.errors));
                return response;
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const editConfigurationalProjectRequest = createAsyncThunk(
    "systemConfiguration/editConfigurationalProjectRequest",
    async ({ values }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });

    // async ({ values }, { rejectWithValue }) => {
        try {
            // const newData = new FormData();
            const response = await apiService(
                POST_PROJECTS_LIST_API + "/" + values.project_id,
                REQUEST_METHOD.PUT,
                values,
                true
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response;
            }
            else {
                errorNotification(Object.values(response.data.errors));
                return response;
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const deleteConfigurationalProjectRequest = createAsyncThunk(
    "systemConfiguration/deleteConfigurationalProjectRequest",
    async (id, { rejectWithValue }) => {
        try {
            // const newData = new FormData();
            const response = await apiService(
                POST_PROJECTS_LIST_API + "/" + id,
                REQUEST_METHOD.DELETE,
                null,
                true
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response;
            }
            else {
                errorNotification(Object.values(response.data.errors));
                return response;
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);

const SystemConfigurationSlice = createSlice({
    name: "systemConfiguration",
    initialState: {
        systemConfigurationListData: null,
        systemConfigurationListStatus: STATUSES.IDLE,
        systemConfigurationListError: null,
        // systemConfiguration Detail
        systemConfigurationDetailData: null,
        systemConfigurationDetailStatus: STATUSES.IDLE,
        systemConfigurationDetailError: null,
        //testimonial SystemConfiguration
        testimonialStatus: STATUSES.IDLE,
        testimonialData: null,
        testimonialError: null,
        // service SystemConfiguration
        serviceStatus: STATUSES.IDLE,
        serviceData: null,
        serviceError: null,
        // project SystemConfiguration
        projectStatus: STATUSES.IDLE,
        projectData: null,
        projectError: null,
    },
    extraReducers: {
        [getSystemConfigurationListRequest.pending]: (state) => {
            state.systemConfigurationListStatus = STATUSES.LOADING;
        },
        [getSystemConfigurationListRequest.fulfilled]: (state, action) => {

            state.systemConfigurationListStatus = STATUSES.IDLE;
            state.systemConfigurationListData = action.payload;
        },
        [getSystemConfigurationListRequest.rejected]: (state, action) => {
            state.systemConfigurationListStatus = STATUSES.ERROR;
            state.systemConfigurationListError = action.payload;
        },
        [getSystemConfigurationDetailRequest.pending]: (state) => {
            state.systemConfigurationDetailStatus = STATUSES.LOADING;
        },
        [getSystemConfigurationDetailRequest.fulfilled]: (state, action) => {

            state.systemConfigurationDetailStatus = STATUSES.IDLE;
            state.systemConfigurationDetailData = action.payload;
        },
        [getSystemConfigurationDetailRequest.rejected]: (state, action) => {
            state.systemConfigurationDetailStatus = STATUSES.ERROR;
            state.systemConfigurationDetailError = action.payload;
        },
        // Testimonial system configurational
        [getConfigurationalTestimonialListRequest.pending]: (state) => {
            state.testimonialStatus = STATUSES.LOADING;
        },
        [getConfigurationalTestimonialListRequest.fulfilled]: (state, action) => {
            state.testimonialStatus = STATUSES.LOADED;
            state.testimonialData = action.payload;
        },
        [getConfigurationalTestimonialListRequest.rejected]: (state, action) => {
            state.testimonialStatus = STATUSES.ERROR;
            state.testimonialError = action.payload;
        },
        // Service system configurational
        [getConfigurationalServiceListRequest.pending]: (state) => {
            state.serviceStatus = STATUSES.LOADING;
        },
        [getConfigurationalServiceListRequest.fulfilled]: (state, action) => {
            state.serviceStatus = STATUSES.LOADED;
            state.serviceData = action.payload;
        },
        [getConfigurationalServiceListRequest.rejected]: (state, action) => {
            state.serviceStatus = STATUSES.ERROR;
            state.serviceError = action.payload;
        },
        // Project system configurational
        [getConfigurationalProjectListRequest.pending]: (state) => {
            state.projectStatus = STATUSES.LOADING;
        },
        [getConfigurationalProjectListRequest.fulfilled]: (state, action) => {
            state.projectStatus = STATUSES.LOADED;
            state.projectData = action.payload;
        },
        [getConfigurationalProjectListRequest.rejected]: (state, action) => {
            state.projectStatus = STATUSES.ERROR;
            state.projectError = action.payload;
        },
    },
});
export default SystemConfigurationSlice.reducer;

