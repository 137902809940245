import { Form, Modal } from "antd";
import React, { useState } from "react";
import SaveButton from "../../../components/button/saveButton";
import BaseInput from "../../../components/form/BaseInput";
import {
  createConfigurationalServiceRequest,
  getConfigurationalServiceListRequest,
} from "../../../redux/systemConfigurationSlice";
import { useDispatch } from "react-redux";
import { BannerImage, STATUSES } from "../../../config/constants/defaultValues";
import BannerImageCard from "../../../components/shared/bannerImageCard";
import TextAreas from "../../../components/form/TextAreas";

const ServiceCreateModal = ({ isModalOpen, setIsModalOpen }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const [serviceImageUrl, setServiceImageUrl] = useState();
  const [serviceImageFile, setServiceImageFile] = useState();

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  //Save Button
  const onSave = async (values) => {
    setIsLoading(true);

    await dispatch(
      createConfigurationalServiceRequest({
        values: {
          ...values,
          service_image: serviceImageFile ?? "",
          status: 1,
        },
      })
    ).then((data) => {
      if (data.payload.status === 200) {
        dispatch(getConfigurationalServiceListRequest({}));
        setIsModalOpen(false);
      }
      setIsLoading(false);
    });
  };
  return (
    <>
      <Modal
        className="modal-styles"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        // width={600}
        maskClosable={false}
        centered={true}
        title={false}
      >
        <div className="row">
          <div className="col-md-12">
            <h5 className="table-heading mb-0 ">Create Service</h5>
          </div>
        </div>
        <div className="modal-content">
          <Form
            layout="vertical"
            className="pt-3"
            onFinish={onSave}
            form={form}
          >
            <div className="row">
              <div className="col-sm-12 d-flex justify-content-between align-items-start">
                <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12">
                  <BaseInput
                    inputIcon={<i className="bi bi-pencil icon_style"></i>}
                    labelText="Service Title"
                    name="title"
                    placeText="Add Service Title"
                  />
                </div>
                <SaveButton
                  loading={isLoading ? STATUSES.LOADING : false}
                  title="Save"
                />
              </div>
            </div>

            <div class="row">
              <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12">
                  <TextAreas
                    labelText="Description"
                    name="description"
                    placeText="Description"
                  />
              </div>
              <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12">
                <BannerImageCard
                  title="User Image"
                  imageUrl={serviceImageUrl ?? BannerImage}
                  isBlogImage={true}
                  setImageFile={setServiceImageFile}
                  setImageUrl={setServiceImageUrl}
                  imageText="Change Image"
                />
              </div>
              <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12"></div>
              
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};
export default ServiceCreateModal;
