const theme = {
  // Ant Design theme customization
  "primary-color": "#112E3C",
  "@drawer-body-padding": 0,
  "@btn-height-base": "50px",
  "@btn-height-lg": "60px",
  "@btn-height-sm": "40px",
  "@btn-default-ghost-border": "#112E3C",
  "@btn-default-ghost-color": "#112E3C",
  "@input-height-base": "50px",
  "@input-height-lg": "60px",
  "@input-height-sm": "35px",
  "@border-radius-base": "4px",
  "@btn-danger-bg": "#33A161",
  "@btn-danger-border": "#33A161",
  "@select-single-item-height-lg": "60px",
  "@tabs-highlight-color": "#33A161",
  "@tabs-hover-color": "#33A161",
  "@tabs-active-color": "#33A161",
  "@tabs-ink-bar-color": "#33A161",
  "@divider-color": "#dee2e6",
  "@timeline-color": "#787CAB",
  "@timeline-item-padding-bottom": "30px",
  "@dropdown-vertical-padding": "10px",
  "@rate-star-color": "#F8B84E",
  "@input-icon-color": "#00A859",
  "@progress-radius": "0",

  // Jumprecruiter global styling
  primaryColor: "#112E3C",
  secondaryColor: "#33A161",
  textColors: {
    textLightestColor: "#40485C",
    textLightColor: "#434343",
    lightGrayColor: "#A39E9E",
  },
  shape: {
    radiusBase: "6px",
  },
  border: {
    color: "rgba(191, 191, 191, 0.09)",
  },
};

export default theme;
