import { Form } from "antd";
import React, { useEffect, useState, } from "react";
import SaveButton from "../../components/button/saveButton";
import BaseInput from "../../components/form/BaseInput";
import { HomeWrapped } from "./style";
import { useDispatch, useSelector } from "react-redux";
import {
  getResidentialSolarPageContentRequest,
  postResidentialSolarPageContentRequest,
} from "../../redux/contentManagementSlice";
import { BannerImage, CONTENT_PAGES, STATUSES } from "../../config/constants/defaultValues";
import Loading from "../../components/shared/GeneralComponents";
import TextAreas from "../../components/form/TextAreas";
import SeoSection from "../../components/shared/seoSection";
import BannerImageCard from "../../components/shared/bannerImageCard";

const ResidentialSolarPage = () => {

  const dispatch = useDispatch();
  const { residentialSolarPageData, residentialSolarPageStatus, residentialSolarPageError } =
    useSelector((state) => state.contentManagement);
  const [form] = Form.useForm();

  const [bannerSectionImageUrl, setBannerSectionImageUrl] = useState();
  const [bannerSectionImageFile, setBannerSectionImageFile] = useState();
  // Second Section
  const [secondSectionImageUrl, setSecondSectionImageUrl] = useState();
  const [secondSectionImageFile, setSecondSectionImageFile] = useState();
  // Third Section
  const [thirdSectionImageUrl, setThirdSectionImageUrl] = useState();
  const [thirdSectionImageFile, setThirdSectionImageFile] = useState();
  // Forth Section
  const [forthSectionImageUrl, setForthSectionImageUrl] = useState();
  const [forthSectionImageFile, setForthSectionImageFile] = useState();
  // Fifth Section
  const [fifthSectionImageUrl, setFifthSectionImageUrl] = useState();
  const [fifthSectionImageFile, setFifthSectionImageFile] = useState();
  //Seo Section states
  const [ogImageUrl, setOGImageUrl] = useState();
  const [ogImageFile, setOGImageFile] = useState();
  const [twitterImageUrl, setTwitterImageUrl] = useState();
  const [twitterImageFile, setTwitterImageFile] = useState();

  useEffect(() => {
    dispatch(getResidentialSolarPageContentRequest(CONTENT_PAGES.RESIDENTIAL_SOLAR_PAGE));
  }, [dispatch]);

  useEffect(() => {
    if (residentialSolarPageData) {
      form.setFieldsValue({
        "detail[bannerSection][text]": residentialSolarPageData?.contentPage?.detail?.bannerSection?.text,
        "detail[bannerSection][heading]": residentialSolarPageData?.contentPage?.detail?.bannerSection?.heading,
        "detail[bannerSection][description]": residentialSolarPageData?.contentPage?.detail?.bannerSection?.description,
        "detail[secondSection][heading]": residentialSolarPageData?.contentPage?.detail?.secondSection?.heading,
        "detail[secondSection][description]": residentialSolarPageData?.contentPage?.detail?.secondSection?.description,
        "detail[thirdSection][heading]": residentialSolarPageData?.contentPage?.detail?.thirdSection?.heading,
        "detail[thirdSection][description]": residentialSolarPageData?.contentPage?.detail?.thirdSection?.description,
        "detail[forthSection][heading]": residentialSolarPageData?.contentPage?.detail?.forthSection?.heading,
        "detail[forthSection][description]": residentialSolarPageData?.contentPage?.detail?.forthSection?.description,
        "detail[fifthSection][heading]": residentialSolarPageData?.contentPage?.detail?.fifthSection?.heading,
        "detail[fifthSection][description]": residentialSolarPageData?.contentPage?.detail?.fifthSection?.description,
        // Testimonials Section
        "detail[testimonialsSection][text]": residentialSolarPageData?.contentPage?.detail?.testimonialsSection?.text,
        "detail[testimonialsSection][heading]": residentialSolarPageData?.contentPage?.detail?.testimonialsSection?.heading,
        // FAQS Section
        "detail[faqsSection][heading]": residentialSolarPageData?.contentPage?.detail?.faqsSection?.heading,
        "detail[faqsSection][buttonText]": residentialSolarPageData?.contentPage?.detail?.faqsSection?.buttonText,
        "detail[faqsSection][firstQuestion]": residentialSolarPageData?.contentPage?.detail?.faqsSection?.firstQuestion,
        "detail[faqsSection][firstAnswer]": residentialSolarPageData?.contentPage?.detail?.faqsSection?.firstAnswer,
        "detail[faqsSection][secondQuestion]": residentialSolarPageData?.contentPage?.detail?.faqsSection?.secondQuestion,
        "detail[faqsSection][secondAnswer]": residentialSolarPageData?.contentPage?.detail?.faqsSection?.secondAnswer,
        "detail[faqsSection][thirdQuestion]": residentialSolarPageData?.contentPage?.detail?.faqsSection?.thirdQuestion,
        "detail[faqsSection][thirdAnswer]": residentialSolarPageData?.contentPage?.detail?.faqsSection?.thirdAnswer,
        "detail[faqsSection][forthQuestion]": residentialSolarPageData?.contentPage?.detail?.faqsSection?.forthQuestion,
        "detail[faqsSection][forthAnswer]": residentialSolarPageData?.contentPage?.detail?.faqsSection?.forthAnswer,
        "detail[faqsSection][fifthQuestion]": residentialSolarPageData?.contentPage?.detail?.faqsSection?.fifthQuestion,
        "detail[faqsSection][fifthAnswer]": residentialSolarPageData?.contentPage?.detail?.faqsSection?.fifthAnswer,
        "detail[faqsSection][sixthQuestion]": residentialSolarPageData?.contentPage?.detail?.faqsSection?.sixthQuestion,
        "detail[faqsSection][sixthAnswer]": residentialSolarPageData?.contentPage?.detail?.faqsSection?.sixthAnswer,

        //Seo Values Set
        meta_title: residentialSolarPageData?.contentPage?.seo_keywords?.meta_title ?? "",
        meta_description: residentialSolarPageData?.contentPage?.seo_keywords?.meta_description ?? "",
        is_canonical: residentialSolarPageData?.contentPage?.seo_keywords?.is_canonical === 1 ? true : false,
        robots_meta: residentialSolarPageData?.contentPage?.seo_keywords?.robots_meta ?? "",
        meta_author: residentialSolarPageData?.contentPage?.seo_keywords?.meta_author ?? "",
        og_type: residentialSolarPageData?.contentPage?.seo_keywords?.og_type ?? "",
        og_title: residentialSolarPageData?.contentPage?.seo_keywords?.og_title ?? "",
        og_description: residentialSolarPageData?.contentPage?.seo_keywords?.og_description ?? "",
        og_image_width: residentialSolarPageData?.contentPage?.seo_keywords?.og_image_width ?? "",
        og_image_height: residentialSolarPageData?.contentPage?.seo_keywords?.og_image_height ?? "",
        og_url: residentialSolarPageData?.contentPage?.seo_keywords?.og_url ?? "",
        og_site_name: residentialSolarPageData?.contentPage?.seo_keywords?.og_site_name ?? "",
        og_locale: residentialSolarPageData?.contentPage?.seo_keywords?.og_locale ?? "",
        og_locale_alternate: residentialSolarPageData?.contentPage?.seo_keywords?.og_locale_alternate ?? "",
        twitter_card: residentialSolarPageData?.contentPage?.seo_keywords?.twitter_card ?? "",
        twitter_site: residentialSolarPageData?.contentPage?.seo_keywords?.twitter_site ?? "",
        twitter_title: residentialSolarPageData?.contentPage?.seo_keywords?.twitter_title ?? "",
        twitter_description: residentialSolarPageData?.contentPage?.seo_keywords?.twitter_description ?? "",
        twitter_url: residentialSolarPageData?.contentPage?.seo_keywords?.twitter_url ?? "",
      });
      setBannerSectionImageUrl(residentialSolarPageData?.contentPage?.contentImages?.bannerSectionImage ?? BannerImage);
      setSecondSectionImageUrl(residentialSolarPageData?.contentPage?.contentImages?.secondSectionImage ?? BannerImage);
      setThirdSectionImageUrl(residentialSolarPageData?.contentPage?.contentImages?.thirdSectionImage ?? BannerImage);
      setForthSectionImageUrl(residentialSolarPageData?.contentPage?.contentImages?.forthSectionImage ?? BannerImage);
      setFifthSectionImageUrl(residentialSolarPageData?.contentPage?.contentImages?.fifthSectionImage ?? BannerImage);
      setTwitterImageUrl(residentialSolarPageData?.contentPage?.seo_keywords?.twitter_image ?? BannerImage);
      setOGImageUrl(residentialSolarPageData?.contentPage?.seo_keywords?.og_image ?? BannerImage);

    }
  }, [residentialSolarPageData]);

  const onUpdate = (values) => {

    const data = {
      ...values,
      name: 'Residential Solar',
      "og_image": ogImageFile ?? "",
      "twitter_image": twitterImageFile ?? "",
      "is_canonical": values.is_canonical === true ? 1 : 0,
      "contentImages[bannerSectionImage]": bannerSectionImageFile ?? "",
      "contentImages[secondSectionImage]": secondSectionImageFile ?? "",
      "contentImages[thirdSectionImage]": thirdSectionImageFile ?? "",
      "contentImages[forthSectionImage]": forthSectionImageFile ?? "",
      "contentImages[fifthSectionImage]": fifthSectionImageFile ?? "",
      _method: 'PUT'
    }
    dispatch(postResidentialSolarPageContentRequest({ data, id: residentialSolarPageData?.contentPage?.id }))
  };

  return (
    <>
      {residentialSolarPageStatus === STATUSES.LOADING ? (
        <Loading />
      ) : residentialSolarPageStatus === STATUSES.ERROR ? (
        <div>{residentialSolarPageError}</div>
      ) : (
        <HomeWrapped>
          <div className="row">
            <div className="col-sm-12">
              <Form
                layout="vertical"
                className="pt-3"
                form={form}
                onFinish={onUpdate}

              >
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-between align-items-start mb-4">
                    <h3 className="font-bold" >Residential Solar</h3>
                    <SaveButton title="Update" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                    {/* Banner Section */}
                    {/* <HeadingTitle title="Banner Section" /> */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Text"
                      name="detail[bannerSection][text]"
                      placeText="Banner Section Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Heading"
                      name="detail[bannerSection][heading]"
                      placeText="Banner Section Heading"
                    />
                    <TextAreas
                      labelText="Banner Section Description"
                      name="detail[bannerSection][description]"
                      placeText="Banner Section Description"
                    />
                    <BannerImageCard
                      title="Banner Section Image"
                      imageUrl={bannerSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setBannerSectionImageFile}
                      setImageUrl={setBannerSectionImageUrl}
                      imageText="Change Image"
                    />
                    {/* Second Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Second Section Heading"
                      name="detail[secondSection][heading]"
                      placeText="Second Section Heading"
                    />
                    <TextAreas
                      labelText="Second Section Description"
                      name="detail[secondSection][description]"
                      placeText="Second Section Description"
                    />
                    <BannerImageCard
                      title="Second Section Image"
                      imageUrl={secondSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setSecondSectionImageFile}
                      setImageUrl={setSecondSectionImageUrl}
                      imageText="Change Image"
                    />
                    {/* Third Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Third Section Heading"
                      name="detail[thirdSection][heading]"
                      placeText="Third Section Heading"
                    />
                    <TextAreas
                      labelText="Third Section Description"
                      name="detail[thirdSection][description]"
                      placeText="Third Section Description"
                    />
                    <BannerImageCard
                      title="Third Section Image"
                      imageUrl={thirdSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setThirdSectionImageFile}
                      setImageUrl={setThirdSectionImageUrl}
                      imageText="Change Image"
                    />
                    {/* Forth Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Forth Section Heading"
                      name="detail[forthSection][heading]"
                      placeText="Forth Section Heading"
                    />
                    <TextAreas
                      labelText="Forth Section Description"
                      name="detail[forthSection][description]"
                      placeText="Forth Section Description"
                    />
                    <BannerImageCard
                      title="Forth Section Image"
                      imageUrl={forthSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setForthSectionImageFile}
                      setImageUrl={setForthSectionImageUrl}
                      imageText="Change Image"
                    />
                    {/* Fifth Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Fifth Section Heading"
                      name="detail[fifthSection][heading]"
                      placeText="Fifth Section Heading"
                    />
                    <TextAreas
                      labelText="Fifth Section Description"
                      name="detail[fifthSection][description]"
                      placeText="Fifth Section Description"
                    />
                    <BannerImageCard
                      title="Fifth Section Image"
                      imageUrl={fifthSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setFifthSectionImageFile}
                      setImageUrl={setFifthSectionImageUrl}
                      imageText="Change Image"
                    />
                    {/* Testimonial section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Testimonials Section Text"
                      name="detail[testimonialsSection][text]"
                      placeText="Testimonials Section Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Testimonials Section Heading"
                      name="detail[testimonialsSection][heading]"
                      placeText="Testimonials Section Heading"
                    />
                    {/* Faq Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="FAQS Section Heading"
                      name="detail[faqsSection][heading]"
                      placeText="FAQS Section Heading"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="FAQS Section Button Text"
                      name="detail[faqsSection][buttonText]"
                      placeText="FAQS Section Button Text"
                    />                  
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="FAQS Section First Question"
                      name="detail[faqsSection][firstQuestion]"
                      placeText="FAQS Section First Question"
                    />
                    <TextAreas
                      labelText="FAQS Section First Answer"
                      name="detail[faqsSection][firstAnswer]"
                      placeText="FAQS Section First Answer"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="FAQS Section Second Question"
                      name="detail[faqsSection][secondQuestion]"
                      placeText="FAQS Section Second Question"
                    />
                    <TextAreas
                      labelText="FAQS Section Second Answer"
                      name="detail[faqsSection][secondAnswer]"
                      placeText="FAQS Section Second Answer"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="FAQS Section Third Question"
                      name="detail[faqsSection][thirdQuestion]"
                      placeText="FAQS Section Third Question"
                    />
                    <TextAreas
                      labelText="FAQS Section Third Answer"
                      name="detail[faqsSection][thirdAnswer]"
                      placeText="FAQS Section Third Answer"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="FAQS Section Forth Question"
                      name="detail[faqsSection][forthQuestion]"
                      placeText="FAQS Section Forth Question"
                    />
                    <TextAreas
                      labelText="FAQS Section Forth Answer"
                      name="detail[faqsSection][forthAnswer]"
                      placeText="FAQS Section Forth Answer"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="FAQS Section Fifth Question"
                      name="detail[faqsSection][fifthQuestion]"
                      placeText="FAQS Section Fifth Question"
                    />
                    <TextAreas
                      labelText="FAQS Section Fifth Answer"
                      name="detail[faqsSection][fifthAnswer]"
                      placeText="FAQS Section Fifth Answer"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="FAQS Section Sixth Question"
                      name="detail[faqsSection][sixthQuestion]"
                      placeText="FAQS Section Sixth Question"
                    />
                    <TextAreas
                      labelText="FAQS Section Sixth Answer"
                      name="detail[faqsSection][sixthAnswer]"
                      placeText="FAQS Section Sixth Answer"
                    />
                  </div>
                  <SeoSection ogImageUrl={ogImageUrl}
                    setOGImageFile={setOGImageFile}
                    setOGImageUrl={setOGImageUrl}
                    twitterImageUrl={twitterImageUrl}
                    setTwitterImageFile={setTwitterImageFile}
                    setTwitterImageUrl={setTwitterImageUrl} />
                </div>
              </Form>
            </div>
          </div>
        </HomeWrapped>
      )}
    </>
  );
};

export default ResidentialSolarPage;
