// export const SERVER_URL = "http://192.168.18.23:22/api";
export const SERVER_URL = "https://dev.acrosolar.co/api";
// export const SERVER_URL = "https://dev.jumprecruiter.com/api";
export const STATUSES = Object.freeze({
  IDLE: "idle",
  LOADING: "loading",
  ERROR: "error",
  LOADED: "error",
});
export const BannerImage = require("../../assets/images/bannerImage.png");

export const USER_TYPE = Object.freeze({
  SUPER_ADMIN: "1",
  SYSTEM_ADMIN: "4",
  JUMP_ADMIN: "5",
  BLOG_ADMIN: "6",
  NONE: "0",
});

export const REQUEST_METHOD = Object.freeze({
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
});
export const CONTENT_PAGES = Object.freeze({
  HOME_PAGE: 1,
  COMMERCIAL_SOLAR_PAGE: 2,
  RESIDENTIAL_SOLAR_PAGE: 3,
  GALLERY_PAGE: 4,
  ABOUT_US_PAGE: 5,
  SOLAR_PANELS_PAGE: 6,
  BATTERY_STORAGE_PAGE: 7,
  TESLA_POWER_WALL_PAGE: 8,
  SHIFT_PAGE: 9,
  LIGHTNING_PAGE: 10,
  CHARGING_PAGE: 11,
  GET_QUOTE_PAGE: 12,
  PLAN_PAGE: 13,
  TERMS_PAGE: 14,
  PRIVACY_PAGE: 15,
  COOKIE_POLICY_PAGE: 16,
  WEBSITE_FOOTER: 17,
});
export const TableDataState = Object.freeze({
  CREATE: 1,
  UPDATE: 2,
  DELETE: 3,
});
export const ImageUploadDefaults = Object.freeze({
  MAX_SIZE: 2097152,// 2MB
  ALLOWED_EXTENSIONS: ".png, .jpg, .jpeg, .svg, .webp"
});
