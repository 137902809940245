import { Form } from "antd";
import React, { useEffect, useState } from "react";
import SaveButton from "../../components/button/saveButton";
import BaseInput from "../../components/form/BaseInput";
import { HomeWrapped } from "./style";
import { useDispatch, useSelector } from "react-redux";
import {
  getCommercialSolarPageContentRequest,
  postCommercialSolarPageContentRequest,
} from "../../redux/contentManagementSlice";
import {
  BannerImage,
  CONTENT_PAGES,
  STATUSES,
} from "../../config/constants/defaultValues";
import Loading from "../../components/shared/GeneralComponents";
import TextAreas from "../../components/form/TextAreas";
import SeoSection from "../../components/shared/seoSection";
import BannerImageCard from "../../components/shared/bannerImageCard";

const CommercialSolarPage = () => {
  const dispatch = useDispatch();
  const {
    commercialSolarPageData,
    commercialSolarPageStatus,
    commercialSolarPageError,
  } = useSelector((state) => state.contentManagement);
  const [form] = Form.useForm();

  const [bannerSectionImageUrl, setBannerSectionImageUrl] = useState();
  const [bannerSectionImageFile, setBannerSectionImageFile] = useState();
  // Second Section
  const [secondSectionImageUrl, setSecondSectionImageUrl] = useState();
  const [secondSectionImageFile, setSecondSectionImageFile] = useState();
  // Third Section
  const [thirdSectionImageUrl, setThirdSectionImageUrl] = useState();
  const [thirdSectionImageFile, setThirdSectionImageFile] = useState();
  // Forth Section
  const [forthSectionImageUrl, setForthSectionImageUrl] = useState();
  const [forthSectionImageFile, setForthSectionImageFile] = useState();
  // Fifth Section
  const [fifthSectionImageUrl, setFifthSectionImageUrl] = useState();
  const [fifthSectionImageFile, setFifthSectionImageFile] = useState();
  //Seo Section states
  const [ogImageUrl, setOGImageUrl] = useState();
  const [ogImageFile, setOGImageFile] = useState();
  const [twitterImageUrl, setTwitterImageUrl] = useState();
  const [twitterImageFile, setTwitterImageFile] = useState();

  useEffect(() => {
    dispatch(
      getCommercialSolarPageContentRequest(CONTENT_PAGES.COMMERCIAL_SOLAR_PAGE)
    );
  }, [dispatch]);

  useEffect(() => {
    if (commercialSolarPageData) {
      form.setFieldsValue({
        "detail[bannerSection][text]":
          commercialSolarPageData?.contentPage?.detail?.bannerSection?.text,
        "detail[bannerSection][heading]":
          commercialSolarPageData?.contentPage?.detail?.bannerSection?.heading,
        "detail[bannerSection][description]":
          commercialSolarPageData?.contentPage?.detail?.bannerSection
            ?.description,
        "detail[secondSection][heading]":
          commercialSolarPageData?.contentPage?.detail?.secondSection?.heading,
        "detail[secondSection][description]":
          commercialSolarPageData?.contentPage?.detail?.secondSection
            ?.description,
        "detail[thirdSection][heading]":
          commercialSolarPageData?.contentPage?.detail?.thirdSection?.heading,
        "detail[thirdSection][description]":
          commercialSolarPageData?.contentPage?.detail?.thirdSection
            ?.description,
        "detail[forthSection][heading]":
          commercialSolarPageData?.contentPage?.detail?.forthSection?.heading,
        "detail[forthSection][description]":
          commercialSolarPageData?.contentPage?.detail?.forthSection
            ?.description,
        "detail[fifthSection][heading]":
          commercialSolarPageData?.contentPage?.detail?.fifthSection?.heading,
        "detail[fifthSection][description]":
          commercialSolarPageData?.contentPage?.detail?.fifthSection
            ?.description,
        // Testimonials Section
        "detail[testimonialsSection][text]":
          commercialSolarPageData?.contentPage?.detail?.testimonialsSection
            ?.text,
        "detail[testimonialsSection][heading]":
          commercialSolarPageData?.contentPage?.detail?.testimonialsSection
            ?.heading,
        // FAQS Section
        "detail[faqsSection][heading]":
          commercialSolarPageData?.contentPage?.detail?.faqsSection?.heading,
        "detail[faqsSection][buttonText]":
          commercialSolarPageData?.contentPage?.detail?.faqsSection?.buttonText,
        "detail[faqsSection][firstQuestion]":
          commercialSolarPageData?.contentPage?.detail?.faqsSection
            ?.firstQuestion,
        "detail[faqsSection][firstAnswer]":
          commercialSolarPageData?.contentPage?.detail?.faqsSection
            ?.firstAnswer,
        "detail[faqsSection][secondQuestion]":
          commercialSolarPageData?.contentPage?.detail?.faqsSection
            ?.secondQuestion,
        "detail[faqsSection][secondAnswer]":
          commercialSolarPageData?.contentPage?.detail?.faqsSection
            ?.secondAnswer,
        "detail[faqsSection][thirdQuestion]":
          commercialSolarPageData?.contentPage?.detail?.faqsSection
            ?.thirdQuestion,
        "detail[faqsSection][thirdAnswer]":
          commercialSolarPageData?.contentPage?.detail?.faqsSection
            ?.thirdAnswer,
        "detail[faqsSection][forthQuestion]":
          commercialSolarPageData?.contentPage?.detail?.faqsSection
            ?.forthQuestion,
        "detail[faqsSection][forthAnswer]":
          commercialSolarPageData?.contentPage?.detail?.faqsSection
            ?.forthAnswer,
        "detail[faqsSection][fifthQuestion]":
          commercialSolarPageData?.contentPage?.detail?.faqsSection
            ?.fifthQuestion,
        "detail[faqsSection][fifthAnswer]":
          commercialSolarPageData?.contentPage?.detail?.faqsSection
            ?.fifthAnswer,
        "detail[faqsSection][sixthQuestion]":
          commercialSolarPageData?.contentPage?.detail?.faqsSection
            ?.sixthQuestion,
        "detail[faqsSection][sixthAnswer]":
          commercialSolarPageData?.contentPage?.detail?.faqsSection
            ?.sixthAnswer,

        //Seo Values Set
        meta_title:
          commercialSolarPageData?.contentPage?.seo_keywords?.meta_title ?? "",
        meta_description:
          commercialSolarPageData?.contentPage?.seo_keywords
            ?.meta_description ?? "",
        is_canonical:
          commercialSolarPageData?.contentPage?.seo_keywords?.is_canonical === 1
            ? true
            : false,
        robots_meta:
          commercialSolarPageData?.contentPage?.seo_keywords?.robots_meta ?? "",
        meta_author:
          commercialSolarPageData?.contentPage?.seo_keywords?.meta_author ?? "",
        og_type:
          commercialSolarPageData?.contentPage?.seo_keywords?.og_type ?? "",
        og_title:
          commercialSolarPageData?.contentPage?.seo_keywords?.og_title ?? "",
        og_description:
          commercialSolarPageData?.contentPage?.seo_keywords?.og_description ??
          "",
        og_image_width:
          commercialSolarPageData?.contentPage?.seo_keywords?.og_image_width ??
          "",
        og_image_height:
          commercialSolarPageData?.contentPage?.seo_keywords?.og_image_height ??
          "",
        og_url:
          commercialSolarPageData?.contentPage?.seo_keywords?.og_url ?? "",
        og_site_name:
          commercialSolarPageData?.contentPage?.seo_keywords?.og_site_name ??
          "",
        og_locale:
          commercialSolarPageData?.contentPage?.seo_keywords?.og_locale ?? "",
        og_locale_alternate:
          commercialSolarPageData?.contentPage?.seo_keywords
            ?.og_locale_alternate ?? "",
        twitter_card:
          commercialSolarPageData?.contentPage?.seo_keywords?.twitter_card ??
          "",
        twitter_site:
          commercialSolarPageData?.contentPage?.seo_keywords?.twitter_site ??
          "",
        twitter_title:
          commercialSolarPageData?.contentPage?.seo_keywords?.twitter_title ??
          "",
        twitter_description:
          commercialSolarPageData?.contentPage?.seo_keywords
            ?.twitter_description ?? "",
        twitter_url:
          commercialSolarPageData?.contentPage?.seo_keywords?.twitter_url ?? "",
      });
      setBannerSectionImageUrl(
        commercialSolarPageData?.contentPage?.contentImages
          ?.bannerSectionImage ?? BannerImage
      );
      setSecondSectionImageUrl(
        commercialSolarPageData?.contentPage?.contentImages
          ?.secondSectionImage ?? BannerImage
      );
      setThirdSectionImageUrl(
        commercialSolarPageData?.contentPage?.contentImages
          ?.thirdSectionImage ?? BannerImage
      );
      setForthSectionImageUrl(
        commercialSolarPageData?.contentPage?.contentImages
          ?.forthSectionImage ?? BannerImage
      );
      setFifthSectionImageUrl(
        commercialSolarPageData?.contentPage?.contentImages
          ?.fifthSectionImage ?? BannerImage
      );
      setTwitterImageUrl(
        commercialSolarPageData?.contentPage?.seo_keywords?.twitter_image ??
          BannerImage
      );
      setOGImageUrl(
        commercialSolarPageData?.contentPage?.seo_keywords?.og_image ??
          BannerImage
      );
    }
  }, [commercialSolarPageData]);

  const onUpdate = (values) => {
    const data = {
      ...values,
      name: "Commercial Solar",
      og_image: ogImageFile ?? "",
      twitter_image: twitterImageFile ?? "",
      is_canonical: values.is_canonical === true ? 1 : 0,
      "contentImages[bannerSectionImage]": bannerSectionImageFile ?? "",
      "contentImages[secondSectionImage]": secondSectionImageFile ?? "",
      "contentImages[thirdSectionImage]": thirdSectionImageFile ?? "",
      "contentImages[forthSectionImage]": forthSectionImageFile ?? "",
      "contentImages[fifthSectionImage]": fifthSectionImageFile ?? "",
      _method: "PUT",
    };
    dispatch(
      postCommercialSolarPageContentRequest({
        data,
        id: commercialSolarPageData?.contentPage?.id,
      })
    );
  };

  return (
    <>
      {commercialSolarPageStatus === STATUSES.LOADING ? (
        <Loading />
      ) : commercialSolarPageStatus === STATUSES.ERROR ? (
        <div>{commercialSolarPageError}</div>
      ) : (
        <HomeWrapped>
          <div className="row">
            <div className="col-sm-12">
              <Form
                layout="vertical"
                className="pt-3"
                form={form}
                onFinish={onUpdate}
              >
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-between align-items-start mb-4">
                    <h3 className="font-bold">Commercial Solar</h3>
                    <SaveButton title="Update" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                    {/* Banner Section */}
                    {/* <HeadingTitle title="Banner Section" /> */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Text"
                      name="detail[bannerSection][text]"
                      placeText="Banner Section Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Heading"
                      name="detail[bannerSection][heading]"
                      placeText="Banner Section Heading"
                    />
                    <TextAreas
                      labelText="Banner Section Description"
                      name="detail[bannerSection][description]"
                      placeText="Banner Section Description"
                    />
                    <BannerImageCard
                      title="Banner Section Image"
                      imageUrl={bannerSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setBannerSectionImageFile}
                      setImageUrl={setBannerSectionImageUrl}
                      imageText="Change Image"
                    />
                    {/* Second Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Second Section Heading"
                      name="detail[secondSection][heading]"
                      placeText="Second Section Heading"
                    />
                    <TextAreas
                      labelText="Second Section Description"
                      name="detail[secondSection][description]"
                      placeText="Second Section Description"
                    />
                    <BannerImageCard
                      title="Second Section Image"
                      imageUrl={secondSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setSecondSectionImageFile}
                      setImageUrl={setSecondSectionImageUrl}
                      imageText="Change Image"
                    />
                    {/* Third Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Third Section Heading"
                      name="detail[thirdSection][heading]"
                      placeText="Third Section Heading"
                    />
                    <TextAreas
                      labelText="Third Section Description"
                      name="detail[thirdSection][description]"
                      placeText="Third Section Description"
                    />
                    <BannerImageCard
                      title="Third Section Image"
                      imageUrl={thirdSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setThirdSectionImageFile}
                      setImageUrl={setThirdSectionImageUrl}
                      imageText="Change Image"
                    />
                    {/* Forth Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Forth Section Heading"
                      name="detail[forthSection][heading]"
                      placeText="Forth Section Heading"
                    />
                    <TextAreas
                      labelText="Forth Section Description"
                      name="detail[forthSection][description]"
                      placeText="Forth Section Description"
                    />
                    <BannerImageCard
                      title="Forth Section Image"
                      imageUrl={forthSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setForthSectionImageFile}
                      setImageUrl={setForthSectionImageUrl}
                      imageText="Change Image"
                    />
                    {/* Fifth Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Fifth Section Heading"
                      name="detail[fifthSection][heading]"
                      placeText="Fifth Section Heading"
                    />
                    <TextAreas
                      labelText="Fifth Section Description"
                      name="detail[fifthSection][description]"
                      placeText="Fifth Section Description"
                    />
                    <BannerImageCard
                      title="Fifth Section Image"
                      imageUrl={fifthSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setFifthSectionImageFile}
                      setImageUrl={setFifthSectionImageUrl}
                      imageText="Change Image"
                    />
                    {/* Testimonial section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Testimonials Section Text"
                      name="detail[testimonialsSection][text]"
                      placeText="Testimonials Section Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Testimonials Section Heading"
                      name="detail[testimonialsSection][heading]"
                      placeText="Testimonials Section Heading"
                    />
                    {/* Faq Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="FAQS Section Heading"
                      name="detail[faqsSection][heading]"
                      placeText="FAQS Section Heading"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="FAQS Section Button Text"
                      name="detail[faqsSection][buttonText]"
                      placeText="FAQS Section Button Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="FAQS Section First Question"
                      name="detail[faqsSection][firstQuestion]"
                      placeText="FAQS Section First Question"
                    />
                    <TextAreas
                      labelText="FAQS Section First Answer"
                      name="detail[faqsSection][firstAnswer]"
                      placeText="FAQS Section First Answer"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="FAQS Section Second Question"
                      name="detail[faqsSection][secondQuestion]"
                      placeText="FAQS Section Second Question"
                    />
                    <TextAreas
                      labelText="FAQS Section Second Answer"
                      name="detail[faqsSection][secondAnswer]"
                      placeText="FAQS Section Second Answer"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="FAQS Section Third Question"
                      name="detail[faqsSection][thirdQuestion]"
                      placeText="FAQS Section Third Question"
                    />
                    <TextAreas
                      labelText="FAQS Section Third Answer"
                      name="detail[faqsSection][thirdAnswer]"
                      placeText="FAQS Section Third Answer"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="FAQS Section Forth Question"
                      name="detail[faqsSection][forthQuestion]"
                      placeText="FAQS Section Forth Question"
                    />
                    <TextAreas
                      labelText="FAQS Section Forth Answer"
                      name="detail[faqsSection][forthAnswer]"
                      placeText="FAQS Section Forth Answer"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="FAQS Section Fifth Question"
                      name="detail[faqsSection][fifthQuestion]"
                      placeText="FAQS Section Fifth Question"
                    />
                    <TextAreas
                      labelText="FAQS Section Fifth Answer"
                      name="detail[faqsSection][fifthAnswer]"
                      placeText="FAQS Section Fifth Answer"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="FAQS Section Sixth Question"
                      name="detail[faqsSection][sixthQuestion]"
                      placeText="FAQS Section Sixth Question"
                    />
                    <TextAreas
                      labelText="FAQS Section Sixth Answer"
                      name="detail[faqsSection][sixthAnswer]"
                      placeText="FAQS Section Sixth Answer"
                    />
                  </div>
                  <SeoSection
                    ogImageUrl={ogImageUrl}
                    setOGImageFile={setOGImageFile}
                    setOGImageUrl={setOGImageUrl}
                    twitterImageUrl={twitterImageUrl}
                    setTwitterImageFile={setTwitterImageFile}
                    setTwitterImageUrl={setTwitterImageUrl}
                  />
                </div>
              </Form>
            </div>
          </div>
        </HomeWrapped>
      )}
    </>
  );
};

export default CommercialSolarPage;
