import { Form } from "antd";
import React, { useEffect, useState, } from "react";
import SaveButton from "../../components/button/saveButton";
import BaseInput from "../../components/form/BaseInput";
import { HomeWrapped } from "./style";
import { useDispatch, useSelector } from "react-redux";
import {
  getGetQuotePageContentRequest,
  postGetQuotePageContentRequest,
} from "../../redux/contentManagementSlice";
import { BannerImage, CONTENT_PAGES, STATUSES } from "../../config/constants/defaultValues";
import Loading from "../../components/shared/GeneralComponents";
import TextAreas from "../../components/form/TextAreas";
import SeoSection from "../../components/shared/seoSection";
import BannerImageCard from "../../components/shared/bannerImageCard";

const GetQuotePage = () => {

  const dispatch = useDispatch();
  const { getQuotePageData, galleryPageStatus, galleryPageError } =
    useSelector((state) => state.contentManagement);
  const [form] = Form.useForm();

  const [bannerSectionImageUrl, setBannerSectionImageUrl] = useState();
  const [bannerSectionImageFile, setBannerSectionImageFile] = useState();
  //Seo Section states
  const [ogImageUrl, setOGImageUrl] = useState();
  const [ogImageFile, setOGImageFile] = useState();
  const [twitterImageUrl, setTwitterImageUrl] = useState();
  const [twitterImageFile, setTwitterImageFile] = useState();

  useEffect(() => {
    dispatch(getGetQuotePageContentRequest(CONTENT_PAGES.GET_QUOTE_PAGE));
  }, [dispatch]);

  useEffect(() => {
    if (getQuotePageData) {
      form.setFieldsValue({
        "detail[bannerSection][heading]": getQuotePageData?.contentPage?.detail?.bannerSection?.heading,
        "detail[bannerSection][firstBulletPoint]": getQuotePageData?.contentPage?.detail?.bannerSection?.firstBulletPoint,
        "detail[bannerSection][secondBulletPoint]": getQuotePageData?.contentPage?.detail?.bannerSection?.secondBulletPoint,
        "detail[bannerSection][thirdBulletPoint]": getQuotePageData?.contentPage?.detail?.bannerSection?.thirdBulletPoint,
        "detail[bannerSection][firstCounterValue]": getQuotePageData?.contentPage?.detail?.bannerSection?.firstCounterValue,
        "detail[bannerSection][firstCounterText]": getQuotePageData?.contentPage?.detail?.bannerSection?.firstCounterText,
        "detail[bannerSection][secondCounterValue]": getQuotePageData?.contentPage?.detail?.bannerSection?.secondCounterValue,
        "detail[bannerSection][secondCounterText]": getQuotePageData?.contentPage?.detail?.bannerSection?.secondCounterText,
        "detail[bannerSection][thirdCounterValue]": getQuotePageData?.contentPage?.detail?.bannerSection?.thirdCounterValue,
        "detail[bannerSection][thirdCounterText]": getQuotePageData?.contentPage?.detail?.bannerSection?.thirdCounterText,
        "detail[bannerSection][forthCounterValue]": getQuotePageData?.contentPage?.detail?.bannerSection?.forthCounterValue,
        "detail[bannerSection][forthCounterText]": getQuotePageData?.contentPage?.detail?.bannerSection?.forthCounterText,
        // Get Quote Section
        "detail[getQuoteSection][formHeading]": getQuotePageData?.contentPage?.detail?.getQuoteSection?.formHeading,
        "detail[getQuoteSection][formConfirmationText]": getQuotePageData?.contentPage?.detail?.getQuoteSection?.formConfirmationText,
        "detail[getQuoteSection][buttonText]": getQuotePageData?.contentPage?.detail?.getQuoteSection?.buttonText,
        // Map Section
        "detail[mapSection][heading]": getQuotePageData?.contentPage?.detail?.mapSection?.heading,
        "detail[mapSection][address]": getQuotePageData?.contentPage?.detail?.mapSection?.address,
        "detail[mapSection][email]": getQuotePageData?.contentPage?.detail?.mapSection?.email,
        "detail[mapSection][phone]": getQuotePageData?.contentPage?.detail?.mapSection?.phone,
        //Seo Values Set
        meta_title: getQuotePageData?.contentPage?.seo_keywords?.meta_title ?? "",
        meta_description: getQuotePageData?.contentPage?.seo_keywords?.meta_description ?? "",
        is_canonical: getQuotePageData?.contentPage?.seo_keywords?.is_canonical === 1 ? true : false,
        robots_meta: getQuotePageData?.contentPage?.seo_keywords?.robots_meta ?? "",
        meta_author: getQuotePageData?.contentPage?.seo_keywords?.meta_author ?? "",
        og_type: getQuotePageData?.contentPage?.seo_keywords?.og_type ?? "",
        og_title: getQuotePageData?.contentPage?.seo_keywords?.og_title ?? "",
        og_description: getQuotePageData?.contentPage?.seo_keywords?.og_description ?? "",
        og_image_width: getQuotePageData?.contentPage?.seo_keywords?.og_image_width ?? "",
        og_image_height: getQuotePageData?.contentPage?.seo_keywords?.og_image_height ?? "",
        og_url: getQuotePageData?.contentPage?.seo_keywords?.og_url ?? "",
        og_site_name: getQuotePageData?.contentPage?.seo_keywords?.og_site_name ?? "",
        og_locale: getQuotePageData?.contentPage?.seo_keywords?.og_locale ?? "",
        og_locale_alternate: getQuotePageData?.contentPage?.seo_keywords?.og_locale_alternate ?? "",
        twitter_card: getQuotePageData?.contentPage?.seo_keywords?.twitter_card ?? "",
        twitter_site: getQuotePageData?.contentPage?.seo_keywords?.twitter_site ?? "",
        twitter_title: getQuotePageData?.contentPage?.seo_keywords?.twitter_title ?? "",
        twitter_description: getQuotePageData?.contentPage?.seo_keywords?.twitter_description ?? "",
        twitter_url: getQuotePageData?.contentPage?.seo_keywords?.twitter_url ?? "",
      });
      setBannerSectionImageUrl(getQuotePageData?.contentPage?.contentImages?.bannerSectionImage ?? BannerImage);
      setTwitterImageUrl(getQuotePageData?.contentPage?.seo_keywords?.twitter_image ?? BannerImage);
      setOGImageUrl(getQuotePageData?.contentPage?.seo_keywords?.og_image ?? BannerImage);
    }
  }, [getQuotePageData]);

  const onUpdate = (values) => {

    const data = {
      ...values,
      name: 'Get Quote Page',
      "contentImages[bannerSectionImage]": bannerSectionImageFile ?? "",
      "og_image": ogImageFile ?? "",
      "twitter_image": twitterImageFile ?? "",
      "is_canonical": values.is_canonical === true ? 1 : 0,
      _method: 'PUT'
    }
    dispatch(postGetQuotePageContentRequest({ data, id: getQuotePageData?.contentPage?.id }))
  };

  return (
    <>
      {galleryPageStatus === STATUSES.LOADING ? (
        <Loading />
      ) : galleryPageStatus === STATUSES.ERROR ? (
        <div>{galleryPageError}</div>
      ) : (
        <HomeWrapped>
          <div className="row">
            <div className="col-sm-12">
              <Form
                layout="vertical"
                className="pt-3"
                form={form}
                onFinish={onUpdate}

              >
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-between align-items-start mb-4">
                    <h3 className="font-bold" >Get Quote Page</h3>
                    <SaveButton title="Update" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                    {/* Banner Section */}
                    {/* <HeadingTitle title="Banner Section" /> */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Heading"
                      name="detail[bannerSection][heading]"
                      placeText="Banner Section Heading"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section First Bullet Point"
                      name="detail[bannerSection][firstBulletPoint]"
                      placeText="Banner Section First Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Second Bullet Point"
                      name="detail[bannerSection][secondBulletPoint]"
                      placeText="Banner Section Second Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Third Bullet Point"
                      name="detail[bannerSection][thirdBulletPoint]"
                      placeText="Banner Section Third Bullet Point"
                    />
                    <BannerImageCard
                      title="Banner Section Image"
                      imageUrl={bannerSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setBannerSectionImageFile}
                      setImageUrl={setBannerSectionImageUrl}
                      imageText="Change Image"
                    />
                    {/* Get Quote section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Get Quote Section Form Heading"
                      name="detail[getQuoteSection][formHeading]"
                      placeText="Get Quote Section Form Heading"
                    />
                    <TextAreas
                      labelText="Get Quote Section Form Confirmation Text"
                      name="detail[getQuoteSection][formConfirmationText]"
                      placeText="Get Quote Section Form Confirmation Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Get Quote Section Button Text"
                      name="detail[getQuoteSection][buttonText]"
                      placeText="Get Quote Section Button Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section First Counter Value"
                      name="detail[bannerSection][firstCounterValue]"
                      placeText="Banner Section First Counter Value"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section First Counter Text"
                      name="detail[bannerSection][firstCounterText]"
                      placeText="Banner Section First Counter Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Second Counter Value"
                      name="detail[bannerSection][secondCounterValue]"
                      placeText="Banner Section Second Counter Value"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Second Counter Text"
                      name="detail[bannerSection][secondCounterText]"
                      placeText="Banner Section Second Counter Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Third Counter Value"
                      name="detail[bannerSection][thirdCounterValue]"
                      placeText="Banner Section Third Counter Value"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Third Counter Text"
                      name="detail[bannerSection][thirdCounterText]"
                      placeText="Banner Section Third Counter Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Forth Counter Value"
                      name="detail[bannerSection][forthCounterValue]"
                      placeText="Banner Section Forth Counter Value"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Forth Counter Text"
                      name="detail[bannerSection][forthCounterText]"
                      placeText="Banner Section Forth Counter Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Map Section Heading"
                      name="detail[mapSection][heading]"
                      placeText="Map Section Heading"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Map Section Address"
                      name="detail[mapSection][address]"
                      placeText="Map Section Address"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Map Section Email"
                      name="detail[mapSection][email]"
                      placeText="Map Section Email"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Map Section Phone"
                      name="detail[mapSection][phone]"
                      placeText="Map Section Phone"
                    />
                  </div>
                  <SeoSection ogImageUrl={ogImageUrl}
                    setOGImageFile={setOGImageFile}
                    setOGImageUrl={setOGImageUrl}
                    twitterImageUrl={twitterImageUrl}
                    setTwitterImageFile={setTwitterImageFile}
                    setTwitterImageUrl={setTwitterImageUrl} />
                </div>
              </Form>
            </div>
          </div>
        </HomeWrapped>
      )}
    </>
  );
};

export default GetQuotePage;
