import { Modal } from "antd";
import React from "react";

const EstimateDetailModal = ({ isModalOpen, setIsModalOpen, modalData }) => {
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const BulletPoints = ({ data }) => {
    console.log(data, "data");
    const items = JSON.parse(data);
    return (
      <ul>
        {items?.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    );
  };
  return (
    <>
      <Modal
        className="modal-styles"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        // width={600}
        maskClosable={false}
        centered={true}
        title={false}
      >
        <div className="row">
          <div className="col-md-12">
            <h5 className="table-heading mb-0 ">Estimate Form Details</h5>
          </div>
        </div>
        <div className="modal-content">
          <div className="section-style">
            <div className="row space-between">
              <div className="col-md-4 px-2">
                <h6 className="heading-title">First Name</h6>
                <h6 className="test-p-list text-break">
                  {modalData?.first_name ?? " "}
                </h6>
              </div>
              <div className="col-md-4 px-2">
                <h6 className="heading-title">Last Name</h6>
                <h6 className="test-p-list text-break">
                  {modalData?.last_name ?? " "}
                </h6>
              </div>
              <div className="col-md-4 px-2">
                <h6 className="heading-title">Email</h6>
                <h6 className="test-p-list text-break">
                  {modalData?.email ?? " "}
                </h6>
              </div>
            </div>
            <div className="row space-between">
              <div className="col-md-4 px-2">
                <h6 className="heading-title">Phone</h6>
                <h6 className="test-p-list text-break">
                  {modalData?.phone ?? " "}
                </h6>
              </div>
              <div className="col-md-4 px-2">
                <h6 className="heading-title">Zip Code</h6>
                <h6 className="test-p-list text-break">
                  {modalData?.zip_code ?? " "}
                </h6>
              </div>
              <div className="col-md-4 px-2">
                <h6 className="heading-title">Roof Type</h6>
                <h6 className="test-p-list text-break">
                  {modalData?.roof_type ?? " "}
                </h6>
              </div>
            </div>
            <div className="row space-between">
              <div className="col-md-4 px-2">
                <h6 className="heading-title">Build Type</h6>
                <h6 className="test-p-list text-break">
                  {modalData?.building_type ?? " "}
                </h6>
              </div>
              <div className="col-md-4 px-2">
                <h6 className="heading-title">Already Installed</h6>
                <h6 className="test-p-list text-break">
                  {modalData?.already_installed ?? " "}
                </h6>
              </div>
              <div className="col-md-4 px-2">
                <h6 className="heading-title">Backup Needed</h6>
                <h6 className="test-p-list text-break">
                  {modalData?.backup_needed ?? " "}
                </h6>
              </div>
            </div>
            <div className="row space-between">
              <div className="col-md-4 px-2">
                <h6 className="heading-title">Monthly Consumed Units</h6>
                <h6 className="test-p-list text-break">
                  {modalData?.monthly_consumed_units ?? " "}
                </h6>
              </div>
              <div className="col-md-4 px-2">
                <h6 className="heading-title">Estimated Panels</h6>
                <h6 className="test-p-list text-break">
                  {modalData?.estimated_panels ?? " "}
                </h6>
              </div>
              <div className="col-md-4 px-2">
                <h6 className="heading-title">Appliances</h6>
                <h6 className="test-p-list text-break">
                  <BulletPoints data={modalData?.appliances} />
                </h6>
              </div>
            </div>
            <div className="row space-between">
              <div className="col-md-4 px-2">
                <h6 className="heading-title">Payment Type</h6>
                <h6 className="test-p-list text-break">
                  {modalData?.payment_type ?? " "}
                </h6>
              </div>
              <div className="col-md-4 px-2">
                <h6 className="heading-title">Credit Score</h6>
                <h6 className="test-p-list text-break">
                  {modalData?.credit_score ?? " "}
                </h6>
              </div>
              <div className="col-md-4 px-2">
                <h6 className="heading-title">Space Size</h6>
                <h6 className="test-p-list text-break">
                  {modalData?.space_size ?? " "}
                </h6>
              </div>
            </div>
            <div className="row space-between">
              <div className="col-md-12 px-2">
                <h6 className="heading-title">Address</h6>
                <h6 className="test-p-list text-break">
                  {modalData?.address ?? " "}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default EstimateDetailModal;
