import { Modal } from "antd";
import React from "react";

const ServiceModal = ({
    isModalOpen,
    setIsModalOpen,
    modalData,
}) => {

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    
    return (
        <>
            <Modal
                className="modal-styles"
                open={isModalOpen}
                onCancel={handleCancel}
                footer={null}
                // width={600}
                maskClosable={false}
                centered={true}
                title={false}
            >
                <div className="row">
                    <div className="col-md-12">
                        <h5 className="table-heading mb-0 ">Service Details</h5>
                    </div>
                </div>
                <div className="modal-content">
                    <div className="section-style">
                        <div className="row space-between">
                            <div className="col-md-12 px-2">
                                <h6 className="heading-title">Title</h6>
                                <h6 className="test-p-list text-break">
                                    {modalData?.title ?? " "}
                                </h6>
                            </div>
                        </div>
                        <div className="row space-between">
                            <div className="col-md-12 px-2">
                                <h6 className="heading-title">Description</h6>
                                <h6 className="test-p-list text-break">
                                    {modalData?.description ?? " "}
                                </h6>
                            </div>
                        </div>
                        <div className="row space-between">
                            <div className="col-md-12 px-2">
                                <h6 className="heading-title">Service Image</h6>
                                <img src={modalData?.service_image} alt="service image" style={{ height: '150px', width: '150px', borderRadius: '50%' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};
export default ServiceModal;
