import { Col, Row } from "antd";
import { HomeStyle } from "./style";
// Images
import { useDispatch, useSelector } from "react-redux";
import { STATUSES, USER_TYPE } from "../config/constants/defaultValues";
import { useEffect } from "react";
import { getDashboardRequest } from "../redux/dashboardSlice";
import Loading from "../components/shared/GeneralComponents";
import DashboardCounterCard from "../components/shared/dashboardCounterCard";
import { Pie, Column } from "@ant-design/plots";
import LineChart from "../components/chart/LineChart";
import EChart from "../components/chart/EChart";

const Home = () => {
  const dispatch = useDispatch();
  const { status, dashboardData, error } = useSelector(
    (state) => state.dashboard
  );
  const { userType } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getDashboardRequest());
  }, [dispatch]);

  const countSeeker = [
    // {
    //   isSeeker: true,
    //   count: dashboardData?.jobsCount ?? 0,
    //   title: "Total Jobs",
    //   // subTitle: "Last 30 days",
    // },
    // {
    //   isSeeker: true,
    //   count: dashboardData?.disciplinesCount ?? 0,
    //   title: "Total Disciplines",
    //   // subTitle: "Last 30 days",
    // },
    // {
    //   isSeeker: true,
    //   count: dashboardData?.applicationsCount ?? 0,
    //   title: "Total Job Applications",
    //   // subTitle: "Last 30 days",
    // },
  ];
  const countEmployer = [
    {
      isSeeker: false,
      count: dashboardData?.contactFormsCount ?? 0,
      title: "Total Quotation received",
      // subTitle: "Last 30 days",
    },
    // {
    //   isSeeker: false,
    //   count: dashboardData?.avg_login_employers ?? 0,
    //   title: "Email Address",
    //   // subTitle: "Last 30 days",
    // },
    // {
    //   isSeeker: false,
    //   count: dashboardData?.avg_job_posts ?? 0,
    //   title: "Total Order",
    //   // subTitle: "Last 30 days",
    // },
  ];

  const config = {
    appendPadding: 10,
    data: dashboardData?.disciplinesWithJobCount ?? [],
    angleField: "jobs_count",
    colorField: "name",
    radius: 0.9,
    label: {
      type: "spider",
      offset: "-30%",
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [
      {
        type: "element-active",
      },
    ],
  };
  const configColumn = {
    data: dashboardData?.jobsWithApplicantCount ?? [],
    xField: "name",
    yField: "applicants_count",
    color: "#112E3C",
    slider: {
      start: 0.0,
      end: 1,
    },
    xAxis: {
      autoRotate: false,
      title: { text: "Jobs with Applications" },
      position: "bottom", // Change the position of the x-axis (e.g., 'top', 'bottom', 'middle')
      label: {
        style: {
          fill: "#112E3C",
        },
      },
    },
    yAxis: {
      // title: { text: 'Applications Count against job' },
      label: {
        style: {
          fill: "green",
          fontSize: 10,
        },
      },
    },

    geometries: [
      {
        type: "interval",
        position: "category*value",
        adjust: [
          {
            type: "dodge",
            marginRatio: 0.2, // Adjust the margin between columns
          },
        ],
        color: "orange", // Change the color of the columns
        size: 20, // Change the column size
      },
    ],
    legend: {
      position: "top", // Change the position of the legend (e.g., 'top', 'bottom', 'left', 'right')
    },
    tooltip: {
      showMarkers: false, // Show or hide data point markers in the tooltip
    },

    // meta: {
    //   type: {
    //     alias: 'Category',
    //   },
    //   sales: {
    //     alias: 'Sales',
    //   },
    // },
  };
  return (
    <>
      {status === STATUSES.LOADING ? (
        <Loading />
      ) : status === STATUSES.ERROR ? (
        <div>{error}</div>
      ) : (
        <HomeStyle>
          <div className="layout-content">
            <Row className="rowgap-vbox" gutter={[24, 0]}>
              {countSeeker.map((item, index) => (
                <Col
                  key={index}
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  className="mb-12"
                >
                  <DashboardCounterCard {...item} />
                </Col>
              ))}
              {countEmployer.map((item, index) => (
                <Col
                  key={index}
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  nm
                  xl={8}
                  className="mb-12 padding_left_right"
                >
                  <DashboardCounterCard {...item} />
                </Col>
              ))}
            </Row>
            <div className="row align-items-center chart-style">
              {/* <div className="col-sm-6">
                <Column  {...configColumn} />
              </div> */}
              <div className="col-md-6">
                {/* <div className="mb-4 mb-lg-0">
                  <EChart
                    seriesData={(
                      dashboardData?.disciplinesWithJobCount ?? []
                    ).map((item) => item.jobs_count)}
                    xCategories={(
                      dashboardData?.disciplinesWithJobCount ?? []
                    ).map((item) => item.name)}
                  />
                </div> */}
              </div>
              <div className="col-md-6">
                <Pie {...config} />
              </div>
            </div>

            {/* <div className="row">
              <div className="col-sm-6">
                <LineChart />
              </div>
            </div> */}
            {(userType === USER_TYPE.SUPER_ADMIN ||
              userType === USER_TYPE.JUMP_ADMIN) && (
              <Row className="rowgap-vbox" gutter={[24, 0]}>
                {/* <Col xs={24} sm={24} md={24} lg={24} xl={12} className="mb-24">
                <div className="project-ant pb-3">
                  <div className="padding-left-6">
                    <Title className="heading" level={5} >
                      Job Approval Request
                    </Title>
                  </div>
                </div>
                <div className="table-responsive">
                  {dashboardData?.jobs.map((job, index) => (
                    <JobApproval
                      imageUrl={job.job_company?.logo}
                      jobTitle={job.job_title?.name}
                      companyName={job.job_company?.name}
                      postTime={job.created_at}
                      id={job.id}
                      key={index}
                    />
                  ))}
                  <div className="style-view">
                    <Link to="/jobrequest" className="btn-style-view">
                      View All
                    </Link>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={12} className="mb-24">
                <div className="project-ant pb-3">
                  <div className="padding-left-6">
                    <Title className="heading" level={5}>
                      Auto Complete Approval Request
                    </Title>
                  </div>
                </div>
                <div className="table-responsive">
                  {dashboardData?.setup_auto_completes.map((setup, index) => (
                    <AutoCompleteSetup
                      jobTitle={setup.value}
                      companyName={setup.model_name}
                      postTime={setup.created_at}
                      viewUrl="/dashboard"
                      key={index}
                    />
                  ))}

                  <div className="style-view">
                    <Link to="/auto-complete" className="btn-style-view">
                      View All
                    </Link>
                  </div>
                </div>
              </Col> */}
              </Row>
            )}
          </div>
        </HomeStyle>
      )}
    </>
  );
};

export default Home;
