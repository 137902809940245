import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { errorNotification, successNotification } from "../components/notifications/alertNotification";
import { REQUEST_METHOD, STATUSES } from "../config/constants/defaultValues";
import { CONTACT_PAGE_API, CONTENT_PAGES_API } from "../config/constants/endPoints";
import { apiService } from "../config/services/api.service";

// HomeContentPage Requests
export const getHomeContentPageContentRequest = createAsyncThunk(
    "contentManagement/getHomeContentPageRequest",
    async (PageID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTENT_PAGES_API + PageID,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postHomeContentPageContentRequest = createAsyncThunk(
    "contentManagement/postHomeContentPageRequest",
    async ({ data, id }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });

        try {
            const response = await apiService(
                CONTENT_PAGES_API + id,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response.data.data;
            } else {
                return errorNotification(response.data.errors);
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// Gallery Page Requests
export const getGalleryPageContentRequest = createAsyncThunk(
    "contentManagement/getGalleryPageContentRequest",
    async (PageID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTENT_PAGES_API + PageID,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postGalleryPageContentRequest = createAsyncThunk(
    "contentManagement/postGalleryPageContentRequest",
    async ({ data, id }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });
        try {
            const response = await apiService(
                CONTENT_PAGES_API + id,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response.data.data;
            } else {
                return errorNotification(response.data.errors);
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// Commercial Solar Page Requests
export const getCommercialSolarPageContentRequest = createAsyncThunk(
    "contentManagement/getCommercialSolarPageContentRequest",
    async (PageID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTENT_PAGES_API + PageID,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postCommercialSolarPageContentRequest = createAsyncThunk(
    "contentManagement/postCommercialSolarPageContentRequest",
    async ({ data, id }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });
        try {
            const response = await apiService(
                CONTENT_PAGES_API + id,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response.data.data;
            } else {
                return errorNotification(response.data.errors);
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// Residential Solar Page Requests
export const getResidentialSolarPageContentRequest = createAsyncThunk(
    "contentManagement/getResidentialSolarPageContentRequest",
    async (PageID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTENT_PAGES_API + PageID,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postResidentialSolarPageContentRequest = createAsyncThunk(
    "contentManagement/postResidentialSolarPageContentRequest",
    async ({ data, id }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });
        try {
            const response = await apiService(
                CONTENT_PAGES_API + id,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response.data.data;
            } else {
                return errorNotification(response.data.errors);
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// Solar Panels Page Requests
export const getSolarPanelsPageContentRequest = createAsyncThunk(
    "contentManagement/getSolarPanelsPageContentRequest",
    async (PageID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTENT_PAGES_API + PageID,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postSolarPanelsPageContentRequest = createAsyncThunk(
    "contentManagement/postSolarPanelsPageContentRequest",
    async ({ data, id }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });
        try {
            const response = await apiService(
                CONTENT_PAGES_API + id,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response.data.data;
            } else {
                return errorNotification(response.data.errors);
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// Battery Storage Page Requests
export const getBatteryStoragePageContentRequest = createAsyncThunk(
    "contentManagement/getBatteryStoragePageContentRequest",
    async (PageID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTENT_PAGES_API + PageID,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postBatteryStoragePageContentRequest = createAsyncThunk(
    "contentManagement/postBatteryStoragePageContentRequest",
    async ({ data, id }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });

        try {
            const response = await apiService(
                CONTENT_PAGES_API + id,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response.data.data;
            } else {
                return errorNotification(response.data.errors);
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// Tesla Power Wall Page Requests
export const getTeslaPowerWallPageContentRequest = createAsyncThunk(
    "contentManagement/getTeslaPowerWallPageContentRequest",
    async (PageID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTENT_PAGES_API + PageID,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postTeslaPowerWallPageContentRequest = createAsyncThunk(
    "contentManagement/postTeslaPowerWallPageContentRequest",
    async ({ data, id }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });

        try {
            const response = await apiService(
                CONTENT_PAGES_API + id,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response.data.data;
            } else {
                return errorNotification(response.data.errors);
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// Shift Page Requests
export const getShiftPageContentRequest = createAsyncThunk(
    "contentManagement/getShiftPageContentRequest",
    async (PageID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTENT_PAGES_API + PageID,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postShiftPageContentRequest = createAsyncThunk(
    "contentManagement/postShiftPageContentRequest",
    async ({ data, id }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });

        try {
            const response = await apiService(
                CONTENT_PAGES_API + id,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response.data.data;
            } else {
                return errorNotification(response.data.errors);
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// Lightning Page Requests
export const getLightningPageContentRequest = createAsyncThunk(
    "contentManagement/getLightningPageContentRequest",
    async (PageID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTENT_PAGES_API + PageID,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postLightningPageContentRequest = createAsyncThunk(
    "contentManagement/postLightningPageContentRequest",
    async ({ data, id }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });

        try {
            const response = await apiService(
                CONTENT_PAGES_API + id,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response.data.data;
            } else {
                return errorNotification(response.data.errors);
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// Charging Page Requests
export const getChargingPageContentRequest = createAsyncThunk(
    "contentManagement/getChargingPageContentRequest",
    async (PageID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTENT_PAGES_API + PageID,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postChargingPageContentRequest = createAsyncThunk(
    "contentManagement/postChargingPageContentRequest",
    async ({ data, id }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });

        try {
            const response = await apiService(
                CONTENT_PAGES_API + id,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response.data.data;
            } else {
                return errorNotification(response.data.errors);
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// About Page Requests
export const getAboutPageContentRequest = createAsyncThunk(
    "contentManagement/getAboutPageContentRequest",
    async (PageID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTENT_PAGES_API + PageID,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postAboutPageContentRequest = createAsyncThunk(
    "contentManagement/postAboutPageContentRequest",
    async ({ data, id }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });
        try {
            const response = await apiService(
                CONTENT_PAGES_API + id,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response.data.data;
            } else {
                return errorNotification(response.data.errors);
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// Terms Page Requests
export const getTermsPageContentRequest = createAsyncThunk(
    "contentManagement/getTermsPageContentRequest",
    async (PageID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTENT_PAGES_API + PageID,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postTermsPageContentRequest = createAsyncThunk(
    "contentManagement/postTermsPageContentRequest",
    async ({ data, id }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });
        try {
            const response = await apiService(
                CONTENT_PAGES_API + id,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response.data.data;
            } else {
                return errorNotification(response.data.errors);
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// Privacy Page Requests
export const getPrivacyPageContentRequest = createAsyncThunk(
    "contentManagement/getPrivacyPageContentRequest",
    async (PageID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTENT_PAGES_API + PageID,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postPrivacyPageContentRequest = createAsyncThunk(
    "contentManagement/postPrivacyPageContentRequest",
    async ({ data, id }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });
        try {
            const response = await apiService(
                CONTENT_PAGES_API + id,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response.data.data;
            } else {
                return errorNotification(response.data.errors);
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// Cookie Policy Page Requests
export const getCookiePolicyPageContentRequest = createAsyncThunk(
    "contentManagement/getCookiePolicyPageContentRequest",
    async (PageID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTENT_PAGES_API + PageID,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postCookiePolicyPageContentRequest = createAsyncThunk(
    "contentManagement/postCookiePolicyPageContentRequest",
    async ({ data, id }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });
        try {
            const response = await apiService(
                CONTENT_PAGES_API + id,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response.data.data;
            } else {
                return errorNotification(response.data.errors);
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// Get Quote Page Requests
export const getGetQuotePageContentRequest = createAsyncThunk(
    "contentManagement/getGetQuotePageContentRequest",
    async (PageID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTENT_PAGES_API + PageID,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postGetQuotePageContentRequest = createAsyncThunk(
    "contentManagement/postGetQuotePageContentRequest",
    async ({ data, id }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });
        try {
            const response = await apiService(
                CONTENT_PAGES_API + id,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response.data.data;
            } else {
                return errorNotification(response.data.errors);
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// Plan Page Requests
export const getPlanPageContentRequest = createAsyncThunk(
    "contentManagement/getPlanPageContentRequest",
    async (PageID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTENT_PAGES_API + PageID,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postPlanPageContentRequest = createAsyncThunk(
    "contentManagement/postPlanPageContentRequest",
    async ({ data, id }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });
        try {
            const response = await apiService(
                CONTENT_PAGES_API + id,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response.data.data;
            } else {
                return errorNotification(response.data.errors);
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
// Web Site Footer Requests
export const getWebSiteFooterContentRequest = createAsyncThunk(
    "contentManagement/getWebSiteFooterContentRequest",
    async (PageID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTENT_PAGES_API + PageID,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const postWebSiteFooterContentRequest = createAsyncThunk(
    "contentManagement/postWebSiteFooterContentRequest",
    async ({ data, id }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });
        try {
            const response = await apiService(
                CONTENT_PAGES_API + id,
                REQUEST_METHOD.POST,
                newData,
                true,
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response.data.data;
            } else {
                return errorNotification(response.data.errors);
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);


export const getBlogContentRequest = createAsyncThunk(
    "contentManagement/contentManagementData",
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTACT_PAGE_API,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);

const contentManagementSlice = createSlice({
    name: "contentManagement",
    initialState: {
        homeContentPageData: null,
        homeContentPageStatus: STATUSES.IDLE,
        homeContentPageError: null,
        // Gallery state
        galleryPageData: null,
        galleryPageStatus: STATUSES.IDLE,
        galleryPageError: null,
        // Why Work Locum Tenens state
        residentialSolarPageData: null,
        residentialSolarPageStatus: STATUSES.IDLE,
        residentialSolarPageError: null,
        // Locum Tenens Page state
        commercialSolarPageData: null,
        commercialSolarPageStatus: STATUSES.IDLE,
        commercialSolarPageError: null,
        // Solar Panels state
        solarPanelsPageData: null,
        solarPanelsPageStatus: STATUSES.IDLE,
        solarPanelsPageError: null,
        // Battery Storage List state 
        batteryStoragePageData: null,
        batteryStoragePageStatus: STATUSES.IDLE,
        batteryStoragePageError: null,
        // Tesla Power Wall List state 
        teslaPowerWallPageData: null,
        teslaPowerWallPageStatus: STATUSES.IDLE,
        teslaPowerWallPageError: null,
        // Shift List state 
        shiftPageData: null,
        shiftPageStatus: STATUSES.IDLE,
        shiftPageError: null,
        // Lightning List state 
        lightningPageData: null,
        lightningPageStatus: STATUSES.IDLE,
        lightningPageError: null,
        // Charging List state 
        chargingPageData: null,
        chargingPageStatus: STATUSES.IDLE,
        chargingPageError: null,
        // AboutUs state
        aboutPageData: null,
        aboutPageStatus: STATUSES.IDLE,
        aboutPageError: null,
        // TermsPage state
        termsPageData: null,
        termsPageStatus: STATUSES.IDLE,
        termsPageError: null,
        // PrivacyPage state
        privacyPageData: null,
        privacyPageStatus: STATUSES.IDLE,
        privacyPageError: null,
        // Cookie Policy Page state
        cookiePolicyPageData: null,
        cookiePolicyPageStatus: STATUSES.IDLE,
        cookiePolicyPageError: null,
        // Get Quote Page state
        getQuotePageData: null,
        getQuotePageStatus: STATUSES.IDLE,
        getQuotePageError: null,
        // Plan Page state
        planPageData: null,
        planPageStatus: STATUSES.IDLE,
        planPageError: null,
        // Web Site Footer state
        webSiteFooterData: null,
        webSiteFooterStatus: STATUSES.IDLE,
        webSiteFooterError: null,        
    },
    extraReducers: {

        // HomeContent
        [getHomeContentPageContentRequest.pending]: (state, action) => {
            state.homeContentPageStatus = STATUSES.LOADING;
        },
        [getHomeContentPageContentRequest.fulfilled]: (state, action) => {

            state.homeContentPageStatus = STATUSES.IDLE;
            state.homeContentPageData = action.payload;
        },
        [getHomeContentPageContentRequest.rejected]: (state, action) => {
            state.homeContentPageStatus = STATUSES.ERROR;
            state.homeContentPageError = action.payload;
        },
        [postHomeContentPageContentRequest.pending]: (state, action) => {
            state.homeContentPageStatus = STATUSES.LOADING;
        },
        [postHomeContentPageContentRequest.fulfilled]: (state, action) => {

            state.homeContentPageStatus = STATUSES.IDLE;
            state.homeContentPageData = action.payload;
        },
        [postHomeContentPageContentRequest.rejected]: (state, action) => {
            state.homeContentPageStatus = STATUSES.ERROR;
            state.homeContentPageError = action.payload;
        },
        // Gallery Page Content
        [getGalleryPageContentRequest.pending]: (state, action) => {
            state.galleryPageStatus = STATUSES.LOADING;
        },
        [getGalleryPageContentRequest.fulfilled]: (state, action) => {
            state.galleryPageStatus = STATUSES.IDLE;
            state.galleryPageData = action.payload;
        },
        [getGalleryPageContentRequest.rejected]: (state, action) => {
            state.galleryPageStatus = STATUSES.ERROR;
            state.galleryPageError = action.payload;
        },
        [postGalleryPageContentRequest.pending]: (state, action) => {
            state.galleryPageStatus = STATUSES.LOADING;
        },
        [postGalleryPageContentRequest.fulfilled]: (state, action) => {
            state.galleryPageStatus = STATUSES.IDLE;
            state.galleryPageData = action.payload;
        },
        [postGalleryPageContentRequest.rejected]: (state, action) => {
            state.galleryPageStatus = STATUSES.ERROR;
            state.galleryPageError = action.payload;
        },
        // Residential Solar Page Content
        [getResidentialSolarPageContentRequest.pending]: (state, action) => {
            state.residentialSolarPageStatus = STATUSES.LOADING;
        },
        [getResidentialSolarPageContentRequest.fulfilled]: (state, action) => {
            state.residentialSolarPageStatus = STATUSES.IDLE;
            state.residentialSolarPageData = action.payload;
        },
        [getResidentialSolarPageContentRequest.rejected]: (state, action) => {
            state.residentialSolarPageStatus = STATUSES.ERROR;
            state.residentialSolarPageError = action.payload;
        },
        [postResidentialSolarPageContentRequest.pending]: (state, action) => {
            state.residentialSolarPageStatus = STATUSES.LOADING;
        },
        [postResidentialSolarPageContentRequest.fulfilled]: (state, action) => {
            state.residentialSolarPageStatus = STATUSES.IDLE;
            state.residentialSolarPageData = action.payload;
        },
        [postResidentialSolarPageContentRequest.rejected]: (state, action) => {
            state.residentialSolarPageStatus = STATUSES.ERROR;
            state.residentialSolarPageError = action.payload;
        },
        // Commercial Solar Page Content
        [getCommercialSolarPageContentRequest.pending]: (state, action) => {
            state.commercialSolarPageStatus = STATUSES.LOADING;
        },
        [getCommercialSolarPageContentRequest.fulfilled]: (state, action) => {

            state.commercialSolarPageStatus = STATUSES.IDLE;
            state.commercialSolarPageData = action.payload;
        },
        [getCommercialSolarPageContentRequest.rejected]: (state, action) => {
            state.commercialSolarPageStatus = STATUSES.ERROR;
            state.commercialSolarPageError = action.payload;
        },
        [postCommercialSolarPageContentRequest.pending]: (state, action) => {
            state.commercialSolarPageStatus = STATUSES.LOADING;
        },
        [postCommercialSolarPageContentRequest.fulfilled]: (state, action) => {

            state.commercialSolarPageStatus = STATUSES.IDLE;
            state.commercialSolarPageData = action.payload;
        },
        [postCommercialSolarPageContentRequest.rejected]: (state, action) => {
            state.commercialSolarPageStatus = STATUSES.ERROR;
            state.commercialSolarPageError = action.payload;
        },
        // Solar Panels Page Content
        [getSolarPanelsPageContentRequest.pending]: (state, action) => {
            state.solarPanelsPageStatus = STATUSES.LOADING;
        },
        [getSolarPanelsPageContentRequest.fulfilled]: (state, action) => {
            state.solarPanelsPageStatus = STATUSES.IDLE;
            state.solarPanelsPageData = action.payload;
        },
        [getSolarPanelsPageContentRequest.rejected]: (state, action) => {
            state.solarPanelsPageStatus = STATUSES.ERROR;
            state.solarPanelsPageError = action.payload;
        },
        [postSolarPanelsPageContentRequest.pending]: (state, action) => {
            state.solarPanelsPageStatus = STATUSES.LOADING;
        },
        [postSolarPanelsPageContentRequest.fulfilled]: (state, action) => {
            state.solarPanelsPageStatus = STATUSES.IDLE;
            state.solarPanelsPageData = action.payload;
        },
        [postSolarPanelsPageContentRequest.rejected]: (state, action) => {
            state.solarPanelsPageStatus = STATUSES.ERROR;
            state.solarPanelsPageError = action.payload;
        },
        // Battery Storage Content
        [getBatteryStoragePageContentRequest.pending]: (state, action) => {
            state.batteryStoragePageStatus = STATUSES.LOADING;
        },
        [getBatteryStoragePageContentRequest.fulfilled]: (state, action) => {
            state.batteryStoragePageStatus = STATUSES.IDLE;
            state.batteryStoragePageData = action.payload;
        },
        [getBatteryStoragePageContentRequest.rejected]: (state, action) => {
            state.batteryStoragePageStatus = STATUSES.ERROR;
            state.batteryStoragePageError = action.payload;
        },
        [postBatteryStoragePageContentRequest.pending]: (state, action) => {
            state.batteryStoragePageStatus = STATUSES.LOADING;
        },
        [postBatteryStoragePageContentRequest.fulfilled]: (state, action) => {
            state.batteryStoragePageStatus = STATUSES.IDLE;
            state.batteryStoragePageData = action.payload;
        },
        [postBatteryStoragePageContentRequest.rejected]: (state, action) => {
            state.batteryStoragePageStatus = STATUSES.ERROR;
            state.batteryStoragePageError = action.payload;
        },
        // Tesla Power Wall Content
        [getTeslaPowerWallPageContentRequest.pending]: (state, action) => {
            state.teslaPowerWallPageStatus = STATUSES.LOADING;
        },
        [getTeslaPowerWallPageContentRequest.fulfilled]: (state, action) => {
            state.teslaPowerWallPageStatus = STATUSES.IDLE;
            state.teslaPowerWallPageData = action.payload;
        },
        [getTeslaPowerWallPageContentRequest.rejected]: (state, action) => {
            state.teslaPowerWallPageStatus = STATUSES.ERROR;
            state.teslaPowerWallPageError = action.payload;
        },
        [postTeslaPowerWallPageContentRequest.pending]: (state, action) => {
            state.teslaPowerWallPageStatus = STATUSES.LOADING;
        },
        [postTeslaPowerWallPageContentRequest.fulfilled]: (state, action) => {
            state.teslaPowerWallPageStatus = STATUSES.IDLE;
            state.teslaPowerWallPageData = action.payload;
        },
        [postTeslaPowerWallPageContentRequest.rejected]: (state, action) => {
            state.teslaPowerWallPageStatus = STATUSES.ERROR;
            state.teslaPowerWallPageError = action.payload;
        },
        // Shift Content
        [getShiftPageContentRequest.pending]: (state, action) => {
            state.shiftPageStatus = STATUSES.LOADING;
        },
        [getShiftPageContentRequest.fulfilled]: (state, action) => {
            state.shiftPageStatus = STATUSES.IDLE;
            state.shiftPageData = action.payload;
        },
        [getShiftPageContentRequest.rejected]: (state, action) => {
            state.shiftPageStatus = STATUSES.ERROR;
            state.shiftPageError = action.payload;
        },
        [postShiftPageContentRequest.pending]: (state, action) => {
            state.shiftPageStatus = STATUSES.LOADING;
        },
        [postShiftPageContentRequest.fulfilled]: (state, action) => {
            state.shiftPageStatus = STATUSES.IDLE;
            state.shiftPageData = action.payload;
        },
        [postShiftPageContentRequest.rejected]: (state, action) => {
            state.shiftPageStatus = STATUSES.ERROR;
            state.shiftPageError = action.payload;
        },
        // Lightning Content
        [getLightningPageContentRequest.pending]: (state, action) => {
            state.lightningPageStatus = STATUSES.LOADING;
        },
        [getLightningPageContentRequest.fulfilled]: (state, action) => {
            state.lightningPageStatus = STATUSES.IDLE;
            state.lightningPageData = action.payload;
        },
        [getLightningPageContentRequest.rejected]: (state, action) => {
            state.lightningPageStatus = STATUSES.ERROR;
            state.lightningPageError = action.payload;
        },
        [postLightningPageContentRequest.pending]: (state, action) => {
            state.lightningPageStatus = STATUSES.LOADING;
        },
        [postLightningPageContentRequest.fulfilled]: (state, action) => {
            state.lightningPageStatus = STATUSES.IDLE;
            state.lightningPageData = action.payload;
        },
        [postLightningPageContentRequest.rejected]: (state, action) => {
            state.lightningPageStatus = STATUSES.ERROR;
            state.lightningPageError = action.payload;
        },
        // Charging Content
        [getChargingPageContentRequest.pending]: (state, action) => {
            state.chargingPageStatus = STATUSES.LOADING;
        },
        [getChargingPageContentRequest.fulfilled]: (state, action) => {
            state.chargingPageStatus = STATUSES.IDLE;
            state.chargingPageData = action.payload;
        },
        [getChargingPageContentRequest.rejected]: (state, action) => {
            state.chargingPageStatus = STATUSES.ERROR;
            state.chargingPageError = action.payload;
        },
        [postChargingPageContentRequest.pending]: (state, action) => {
            state.chargingPageStatus = STATUSES.LOADING;
        },
        [postChargingPageContentRequest.fulfilled]: (state, action) => {
            state.chargingPageStatus = STATUSES.IDLE;
            state.chargingPageData = action.payload;
        },
        [postChargingPageContentRequest.rejected]: (state, action) => {
            state.chargingPageStatus = STATUSES.ERROR;
            state.chargingPageError = action.payload;
        },
        // About Page Content
        [getAboutPageContentRequest.pending]: (state, action) => {
            state.aboutPageStatus = STATUSES.LOADING;
        },
        [getAboutPageContentRequest.fulfilled]: (state, action) => {

            state.aboutPageStatus = STATUSES.IDLE;
            state.aboutPageData = action.payload;
        },
        [getAboutPageContentRequest.rejected]: (state, action) => {
            state.aboutPageStatus = STATUSES.ERROR;
            state.aboutPageError = action.payload;
        },
        [postAboutPageContentRequest.pending]: (state, action) => {
            state.aboutPageStatus = STATUSES.LOADING;
        },
        [postAboutPageContentRequest.fulfilled]: (state, action) => {

            state.aboutPageStatus = STATUSES.IDLE;
            state.aboutPageData = action.payload;
        },
        [postAboutPageContentRequest.rejected]: (state, action) => {
            state.aboutPageStatus = STATUSES.ERROR;
            state.aboutPageError = action.payload;
        },
        // Terms Page
        [getTermsPageContentRequest.pending]: (state, action) => {
            state.termsPageStatus = STATUSES.LOADING;
        },
        [getTermsPageContentRequest.fulfilled]: (state, action) => {
            state.termsPageStatus = STATUSES.IDLE;
            state.termsPageData = action.payload;
        },
        [getTermsPageContentRequest.rejected]: (state, action) => {
            state.termsPageStatus = STATUSES.ERROR;
            state.termsPageError = action.payload;
        },
        [postTermsPageContentRequest.pending]: (state, action) => {
            state.termsPageStatus = STATUSES.LOADING;
        },
        [postTermsPageContentRequest.fulfilled]: (state, action) => {

            state.termsPageStatus = STATUSES.IDLE;
            state.termsPageData = action.payload;
        },
        [postTermsPageContentRequest.rejected]: (state, action) => {
            state.termsPageStatus = STATUSES.ERROR;
            state.termsPageError = action.payload;
        },
        // Privacy Page
        [getPrivacyPageContentRequest.pending]: (state, action) => {
            state.privacyPageStatus = STATUSES.LOADING;
        },
        [getPrivacyPageContentRequest.fulfilled]: (state, action) => {
            state.privacyPageStatus = STATUSES.IDLE;
            state.privacyPageData = action.payload;
        },
        [getPrivacyPageContentRequest.rejected]: (state, action) => {
            state.privacyPageStatus = STATUSES.ERROR;
            state.privacyPageError = action.payload;
        },
        [postPrivacyPageContentRequest.pending]: (state, action) => {
            state.privacyPageStatus = STATUSES.LOADING;
        },
        [postPrivacyPageContentRequest.fulfilled]: (state, action) => {
            state.privacyPageStatus = STATUSES.IDLE;
            state.privacyPageData = action.payload;
        },
        [postPrivacyPageContentRequest.rejected]: (state, action) => {
            state.privacyPageStatus = STATUSES.ERROR;
            state.privacyPageError = action.payload;
        },
        // cookiePolicy
        [getCookiePolicyPageContentRequest.pending]: (state, action) => {
            state.cookiePolicyPageStatus = STATUSES.LOADING;
        },
        [getCookiePolicyPageContentRequest.fulfilled]: (state, action) => {

            state.cookiePolicyPageStatus = STATUSES.IDLE;
            state.cookiePolicyPageData = action.payload;
        },
        [getCookiePolicyPageContentRequest.rejected]: (state, action) => {
            state.cookiePolicyPageStatus = STATUSES.ERROR;
            state.cookiePolicyPageError = action.payload;
        },
        [postCookiePolicyPageContentRequest.pending]: (state, action) => {
            state.cookiePolicyPageStatus = STATUSES.LOADING;
        },
        [postCookiePolicyPageContentRequest.fulfilled]: (state, action) => {

            state.cookiePolicyPageStatus = STATUSES.IDLE;
            state.cookiePolicyPageData = action.payload;
        },
        [postCookiePolicyPageContentRequest.rejected]: (state, action) => {
            state.cookiePolicyPageStatus = STATUSES.ERROR;
            state.cookiePolicyPageError = action.payload;
        },
        // get QuotePage
        [getGetQuotePageContentRequest.pending]: (state, action) => {
            state.getQuotePageStatus = STATUSES.LOADING;
        },
        [getGetQuotePageContentRequest.fulfilled]: (state, action) => {

            state.getQuotePageStatus = STATUSES.IDLE;
            state.getQuotePageData = action.payload;
        },
        [getGetQuotePageContentRequest.rejected]: (state, action) => {
            state.getQuotePageStatus = STATUSES.ERROR;
            state.getQuotePageError = action.payload;
        },
        [postGetQuotePageContentRequest.pending]: (state, action) => {
            state.getQuotePageStatus = STATUSES.LOADING;
        },
        [postGetQuotePageContentRequest.fulfilled]: (state, action) => {

            state.getQuotePageStatus = STATUSES.IDLE;
            state.getQuotePageData = action.payload;
        },
        [postGetQuotePageContentRequest.rejected]: (state, action) => {
            state.getQuotePageStatus = STATUSES.ERROR;
            state.getQuotePageError = action.payload;
        },
        // Plan Page Data
        [getPlanPageContentRequest.pending]: (state, action) => {
            state.planPageStatus = STATUSES.LOADING;
        },
        [getPlanPageContentRequest.fulfilled]: (state, action) => {

            state.planPageStatus = STATUSES.IDLE;
            state.planPageData = action.payload;
        },
        [getPlanPageContentRequest.rejected]: (state, action) => {
            state.planPageStatus = STATUSES.ERROR;
            state.planPageError = action.payload;
        },
        [postPlanPageContentRequest.pending]: (state, action) => {
            state.planPageStatus = STATUSES.LOADING;
        },
        [postPlanPageContentRequest.fulfilled]: (state, action) => {

            state.planPageStatus = STATUSES.IDLE;
            state.planPageData = action.payload;
        },
        [postPlanPageContentRequest.rejected]: (state, action) => {
            state.planPageStatus = STATUSES.ERROR;
            state.planPageError = action.payload;
        },
        // Web Site Footer
        [getWebSiteFooterContentRequest.pending]: (state, action) => {
            state.webSiteFooterStatus = STATUSES.LOADING;
        },
        [getWebSiteFooterContentRequest.fulfilled]: (state, action) => {
            state.webSiteFooterStatus = STATUSES.IDLE;
            state.webSiteFooterData = action.payload;
        },
        [getWebSiteFooterContentRequest.rejected]: (state, action) => {
            state.webSiteFooterStatus = STATUSES.ERROR;
            state.webSiteFooterError = action.payload;
        },
        [postWebSiteFooterContentRequest.pending]: (state, action) => {
            state.webSiteFooterStatus = STATUSES.LOADING;
        },
        [postWebSiteFooterContentRequest.fulfilled]: (state, action) => {
            state.webSiteFooterStatus = STATUSES.IDLE;
            state.webSiteFooterData = action.payload;
        },
        [postWebSiteFooterContentRequest.rejected]: (state, action) => {
            state.webSiteFooterStatus = STATUSES.ERROR;
            state.webSiteFooterError = action.payload;
        },        
    },
});
export default contentManagementSlice.reducer;

